import React, { useState } from "react";
import RequestList from "components/Table/PageNationTable";
import ActionDropdown from "components/Table/Actions";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import Titleform from "./components/profileForm";
import Widget from "components/Widget";
import ShowModal from "components/Modal/ShowModal";
import DeleteStatus from "components/Modal/DeleteStatus";
import moment from "moment";
import RequestChart from "./components/RequestCharts";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { intlPlaceholder } from "util/intlPlaceholder";
import { renderStatus } from "utils/commonUseFunction";
import { useDispatch } from "react-redux";
const Request = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState({});

  

  const ProfileForm = (record) => {
    dispatch({
      ModalName: "Title_Form_Modal",
      type: "Show_Modal",
      payload: record,
    });
  };
  const setUserStatus = (_id, status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "updateMeetingRequestForAdmin",
        "EditTableRecord",
        { _id: _id, status: status },
        "StartSpinner",
        "",
        "requestList"
      )
    );
  };
  const renderActions = (record) => {




    return (
      <div style={{gap:"5px"}} className="gx-d-flex gx-flex-column popoverContent">
        {record.status !== "cancelled" && (
          <ShowModal
            modalName={"Title_Form_Modal"}
            buttonName="View"
            record={record}
          />
        )} 
        {record.status === "pending" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "schedule")}
          >
            Approve
          </span>
        )}
        {record.status === "pending" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "cancelled")}
          >
            Reject
          </span>
        )}
        {record.status === "schedule" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "completed")}
          >
            Finish
          </span>
        )}
        {record.status === "schedule" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "cancelled")}
          >
            Cancel
          </span>
        )}

     
          <DeleteStatus
          id={record._id}
          Status="deleted"
          statusApiName="deleteMeetingRequestForAdmin"
          recordName="requestList"
          apiRequest="DeleteTableRecord"
          buttonName={intlPlaceholder("status.buttonName")}
          oKText={intlPlaceholder("status.oKText")}
          title={intlPlaceholder("status.deleteTitle")}
          content={intlPlaceholder("status.deleteContent")}
          Icon={intlPlaceholder("status.actionDeleteName")}
        />
       
       
      </div>
    ); 
  };

  const columns = [
    {
      title: intlPlaceholder("request.ID"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.meetingId || "----"}</span>
      ),
      key: "requestId",
      width: "100px",
    },
    {
      title: intlPlaceholder("request.title"),
      render: (record) => (
        <span className="gx-d-flex gx-align-items-center">
          <Avatar
            className="gx-mr-2"
            size={45}
            src={(record.profileImageUrl && record.profileImageUrl.length && record.profileImageUrl[0].imageUrl) || ""}
            icon={!record.profileImageUrl[0] && <UserOutlined />}
          />
          <span
            className="gx-link gx-text-blue gx-text-capitalize"
            onClick={() => {
              ProfileForm(record);
            }}
          >
            {" "}
            {record.fullName || "----"}
          </span>
        </span>
      ),
      key: "title",
      width: "600px",
    },
    {
      title: intlPlaceholder("request.role" ),
      render: (record) => (
        <span className="gx-text-capitalize">{record.role || "----"}</span>
      ),
      key: "role",
       width:"300px"
    },
    // {
    //   title: intlPlaceholder("request.day"),
    //   render: (record) => (
    //     <span className="gx-text-capitalize">{record.meetingRequestedDay}</span>
    //   ),
    //   key: "day",
    //   width:"300px"
    // },
    {
      title: <span style={{whiteSpace:"nowrap"}}>{"Reservation With"}</span>,
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.reservationWithName || "----"}
        </span>
      ),
      key: "ReservationWith",
    },
    {
      title: intlPlaceholder("request.date"),
      render: (record) => (
        <span className="gx-text-capitalize">{moment(record.meetingDate).format("MM-DD-YYYY"  || "----")}</span>
      ),
      key: "date",
      width:"600px"
    },
    {
      title: intlPlaceholder("request.time"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.meetingTime  || "----"}</span>
      ),
      key: "time",
      width: "700px",
    },

    {
      title: intlPlaceholder("instructor.Status"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status )}
        </span>
      ),
      key: "status",
    
    },
    {
      title: intlPlaceholder("request.meetingAgenda"),
      render: (record) => (
        <span className=" gx-text-capitalize gx-overflow-ellipsis gx-pointer gx-pr-5=">
          {record.meetingAgenda
            ? record.meetingAgenda.length > 30
              ? record.meetingAgenda.slice(0, 20) + "..."
              : record.meetingAgenda
            : ""}
        </span>
      ),
      key: "meetingAgenda",
     width:"500px"
    },

    {
      title: "",
      render: (record) => (
        <ActionDropdown>{renderActions(record)}</ActionDropdown>
      ),
      key: "action",
      width: "100px",
    },
  ];

  return (
    <Layout>
      <RequestChart />
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <RequestList
          apiName="getMeetingRequest"
          recordName="requestList"
          
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
      <Titleform />
    </Layout>
  );
};

export default Request;
