import React from "react";
import Page404 from "assets/images/Page404.webp";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
const PageError = () => {
  const history = useHistory();
  return (
    <div
      style={{ background: "#F1F1FD" }}
      className="gx-d-flex gx-flex-column gx-align-items-center gx-justify-content-center gx-h-100"
    >

        <img
          style={{ borderStyle: "dotted", color: "black" }}
          src={Page404}
          alt="errorPage"
        />
     
      <span className="gx-mt-5 ">
        <Button
          className="gx-py-0 gx-font-sans-italic"
          style={{ width: "150px", height: "45px" ,fontSize:"20px" }}
          onClick={() => history.push("/dashboard")}
        >
          Go Back
        </Button>
      </span>
    </div>
  );
};

export default PageError;
