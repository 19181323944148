import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Button, Select, message } from "antd";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { intlPlaceholder } from "util/intlPlaceholder";
const AssignedInstructor = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const curdProps = useSelector((state) => state.CurdR);
  const {
    AssignedInstructor_Modal,
    assignedInstructorList,
    studentSuccess,
    success,
    initialValues,
  } = curdProps;



  const onFinish = (values) => {
    values._id = initialValues && initialValues._id
    dispatch(
      DataRequestAction(
        "PUT",
        "changeInstructor",
        "EditTableRecord",
        values,
        "StudentSpinner",
        "AssignedInstructor_Modal",
        "instructorList"
      )
    );
  };
  if (success) {
    form.resetFields();
    dispatch({
      ModalName: "AssignedInstructor_Modal",
      type: "Hide_Modal",
      payload: null,
    });
    message.success("");

    dispatch({
      type: "ResetAll_State",
    });
  }

  const CloseModal = () => {
    dispatch({
      ModalName: "AssignedInstructor_Modal",
      type: "Hide_Modal",
      payload: null,
    });
    form.resetFields();
  };

  return (
    <div>
      {" "}
      <SimpleModal
        width={530}
        title={
          <span className="gx-font-sans-bold">
            {intlPlaceholder("Assign Instructor")}
          </span>
        }
        modalFor="AssignedInstructor_Modal"
        visible={AssignedInstructor_Modal}
      >
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="AssignedInstructor"
        >
          <Form.Item
            label={intlPlaceholder("course.instructorName")}
            name="assignTo"
          >
            <Select
              showSearch
              placeholder={intlPlaceholder("course.instructorNameplaceholder")}
            >
              {assignedInstructorList &&
                assignedInstructorList.map((item) => {
                  if(initialValues &&initialValues._id !==item._id)
                  return (
                    <Select.Option value={item._id}>
                      {item.fullName}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
              <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end gx-mr-3">
                <Form.Item className="gx-mb-0">
                  <Button
                    type="default"
                    className="gx-pt-0 gx-mb-0 gx-mr-3"
                    htmlType="button"
                    onClick={() => CloseModal()}
                  >
                    {intlPlaceholder("instructor.cancelbutton")}
                  </Button>
                </Form.Item>
                <Form.Item className="gx-mb-0">
                  <Button
                    type="primary"
                    className="gx-pt-0 gx-mb-0 gx-gradient-button"
                    htmlType="submit"
                    disabled={studentSuccess}
                    loading={studentSuccess}
                  >
                    {intlPlaceholder("instructor.updatebutton")}
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </SimpleModal>
    </div>
  );
};

export default AssignedInstructor;
