import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Row, Col, Button,message } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import { DataRequestAction } from "redux/actions/CommonHttp";
const ResetPasswordModal = () => {
  const [form] = Form.useForm();
 
 
  const curdProps = useSelector((state) => state.CurdR);
  const {
    Reset_Password_Modal,
    initialValues,
    
  } = curdProps;
  const authProps = useSelector((state) => state.auth);
  const { fetchLoader,  isPasswordChanged, resetFail,textMessage } = authProps
 
  const dispatch = useDispatch();

  const changePassword = (values) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "resetPassword",
        "resetPassword",
        { _id: initialValues, password: values },
        "resetSpinner",
        "",
        ""
      )
    ); 
  }; 
  
  if (isPasswordChanged) {
    
    form.resetFields();
    dispatch({
      ModalName: "Reset_Password_Modal",
      type: "Hide_Modal",
      payload: null,
    });
    message.success(textMessage);
  
    dispatch({
      type: "reset_profilesettings",
    });
  }

  if (resetFail) {
    message.error(textMessage);
    dispatch({
      type: "reset_profilesettings",
    });
    form.resetFields();
  }
  const CloseModal = () => {
    dispatch({
      ModalName: "Reset_Password_Modal",
      type: "Hide_Modal",
      payload: null,
    });
    form.resetFields();
  };
  return (
    <>
      <SimpleModal
        width={530}
        title={
          <span className="gx-font-sans-bold">
            {intlPlaceholder("instructor.modalTitle")}
          </span>
        }
        modalFor="Reset_Password_Modal"
        visible={Reset_Password_Modal}
      >
        <Form form={form} layout="vertical" name="resetPassword" onFinish={changePassword}>
          <Row className="gx-flex-row gx-px-0 gx-mx-0">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                label={intlPlaceholder("instructor.password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: intlPlaceholder("instructor.passwordmessage"),
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={intlPlaceholder(
                    "instructor.passwordPlaceholder"
                  )}
                />
              </Form.Item>

              <Form.Item
                className="gx-mt-2"
                label={intlPlaceholder("instructor.confirmpassword")}
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: intlPlaceholder(
                      "instructor.confirmpasswordmessage"
                    ),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Password did not match")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={intlPlaceholder(
                    "instructor.confirmPasswordPlaceholder"
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
              <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end gx-mr-3">
                <Form.Item className="gx-mb-0">
                  <Button
                    type="default"
                    className="gx-pt-0 gx-mb-0 gx-mr-3"
                    htmlType="button"
                    onClick={() => CloseModal()}
                  >
                    {intlPlaceholder("instructor.cancelbutton")}
                  </Button>
                </Form.Item>
                <Form.Item className="gx-mb-0">
                  <Button
                    type="primary"
                    className="gx-pt-0 gx-mb-0 gx-gradient-button"
                    htmlType="submit"
                    disabled={fetchLoader}
                    loading={fetchLoader}
                  >
                    {intlPlaceholder("instructor.updatebutton")}
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </SimpleModal>
    </>
  );
};

export default ResetPasswordModal;
