import React from "react";
import AddAssignment from "./addAssignment";
import DateRangPicker from "components/Filters/DateRangPicker";
import SelectFilter from "components/Filters/SelectFilter";
import Title from "components/Title";

const TableHeader = ({ setFilterValue }) => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <Title name={"Assignment"} />

      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
        <DateRangPicker
          customQuery={{ query: "date" }}
          apiName="getAssignment"
          recordName="assignmentList"
          requestType="FetchTableRecord"
          width="230px"
        />
        <SelectFilter
          apiName="getAssignment"
          recordName="assignmentList"
          requestType="FetchTableRecord"
          placeholder={"status"}
          searchKey="status"
          width={"150px"}
          isShowingAll={false}
          options={[
            { status: "open", label: "Open" },
            { status: "close", label: "Close" },
          ]}
          ItemValue="status"
          label="label"
        />
        <AddAssignment />
      </div>
    </div>
  );
};

export default TableHeader;
