import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import moment from "moment";

import { useSelector } from "react-redux";
const ViewNotification = () => {
  const curdProps = useSelector((state) => state.CurdR);
  const { View_Notification_Modal, initialValues } = curdProps;

  return (
    <>
      <SimpleModal
        width={500}
        modalFor="View_Notification_Modal"
        visible={View_Notification_Modal}
      >
        <div className="gx-d-flex gx-flex-column gx-align-items-center gx-justify-content-center gx-mt-4">
          <h3>{initialValues && initialValues.title}</h3>
          <p className="gx-mt-0">
            Created by{" "}
            <span className="gx-font-sans-bold  gx-px-1 gx-text-capitalize">
              {initialValues && initialValues.createdBy}
            </span>
            {initialValues &&
              moment(initialValues.createdAt).format("DD-MMM-YYYY")}
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: initialValues && initialValues.description,
            }}
            className="gx-mt-2"
          />
        </div>
      </SimpleModal>
    </>
  );
};

export default ViewNotification;
