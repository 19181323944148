import React, { useEffect } from "react";
import Layout from "components/Layout";
import moment from "moment";
import dummy from "assets/images/dummyImage.jpeg";
import { DataGetAction } from "redux/actions/CommonHttp";
import { Card, Row, Col, Divider, Spin, Empty } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ViewInstructor = () => {
  const params = useLocation();

  const { _id } = params && params.state;
  const dispatch = useDispatch();
  const curdProps = useSelector((state) => state.CurdR);
  const { InstructorDetailList, Loader } = curdProps;

  const fetchInstructorData = () => {
    dispatch(
      DataGetAction(
        "getInstructorDetails",
        "FetchRecord",
        { _id: _id },
        "StartSpinner",
        "",
        "InstructorDetailList"
      )
    );
  };
  useEffect(fetchInstructorData, []);

  const InstructorDetails = (name, value) => {
    return (
      <Col xl={6} lg={6} md={6} xs={12} sm={12}>
        <div className="gx-d-flex gx-flex-column gx-py-1">
          <span className="gx-mb-2 gx-text-center gx-font-sans-bold gx-font-16">
            {name}
          </span>
          <span
            style={{}}
            className="gx-pointer gx-text-center gx-overflow-ellipsis  gx-font-sans-regular gx-font-14 gx-px-4"
          >
            {value
              ? value.length > 10
                ? value.slice(0, 18) + "..."
                : value
              : ""}
          </span>
        </div>
      </Col>
    );
  };
  const instructorCertification = (name, values) => {
    return (
      <div
        className="gx-mt-5 gx-d-flex gx-flex-column "
        style={{ overflowY: "auto", height: "300px" }}
      >
        <span className="gx-font-sans-bold gx-mb-3 gx-font-18">{name}</span>

        {values && values[0] === "" ? (
          <Empty />
        ) : (
          values.map((items) => {
            return (
              <>
                <p className="gx-font-sans-regular gx-font-16">{items}</p>
                <Divider className="gx-mt-0" />
              </>
            );
          })
        )}
      </div>
    );
  };
  return (
    <Layout>
      {Loader && <Spin></Spin>}
      {!Loader &&
        InstructorDetailList &&
        InstructorDetailList.map((item) => {
          const aboutMe = item.aboutMe;

          console.log("====aboutMe", aboutMe);
          return (
            <Row>
              <Col xl={6} lg={6} md={6} xs={24} sm={24}>
                <Card
                  bodyStyle={{ padding: "12px" }}
                  className="gx-student-card-style"
                >
                  <div className="gx-d-flex gx-flex-column gx-align-items-center gx-justify-content-center">
                    <img
                      className="gx-mb-3 gx-student-img-style"
                      src={
                        (item &&
                          item.profileImageUrl.length &&
                          item.profileImageUrl[0].imageUrl) ||
                        dummy
                      }
                      alt=""
                    />
                    <span className="gx-font-sans-semibold gx-font-18">
                      {item.fullName}
                    </span>
                  </div>
                </Card>
                {InstructorDetailList && !InstructorDetailList.length > 0 && (
                  <div className="gx-d-flex gx-h-100 gx-align-items-center gx-justify-content-center">
                    <Empty />
                  </div>
                )}

                <Card
                  bodyStyle={{ padding: "12px" }}
                  className="gx-student-card-style gx-px-2 "
                  title={
                    <span className="gx-student-header-style gx-font-sans-medium gx-font-18">
                      About Me
                    </span>
                  }
                >
                  <div className="gx-d-flex gx-flex-column gx-font-sans-regular gx-text-capitalize gx-font-14">
                    <p
                      style={{ height: "200px", overflowX: "hidden" }}
                      dangerouslySetInnerHTML={{ __html: aboutMe }}
                      className="gx-mt-2"
                    />
                    <div className="gx-d-flex gx-justify-content-between gx-mb-0">
                      <span className="gx-font-sans-bold gx-font-16">
                        Gender
                      </span>
                      <span className="gx-font-sans-medium gx-text-capitalize gx-font-14">
                        {item.gender}
                      </span>
                    </div>
                    <hr
                      style={{ border: "1px solid #D1CED1" }}
                      className="gx-mt-0"
                    />
                    <div className="gx-d-flex gx-justify-content-between gx-mb-0">
                      <span className="gx-font-sans-bold gx-font-16">
                        Designation
                      </span>
                      <span className="gx-font-sans-medium gx-text-capitalize gx-font-14">
                        {item.designation}
                      </span>
                    </div>
                    <hr
                      style={{ border: "1px solid #D1CED1" }}
                      className="gx-mt-0"
                    />
                  </div>
                </Card>

                <Card
                  bodyStyle={{ padding: "12px" }}
                  className="gx-student-card-style gx-px-2 "
                  title={
                    <span className="gx-student-header-style gx-font-sans-medium gx-font-18">
                      Address
                    </span>
                  }
                >
                  <div className="gx-d-flex gx-flex-column gx-font-sans-regular gx-text-capitalize  gx-font-14">
                    <p
                      style={{ height: "150px", overflowX: "hidden" }}
                      className="gx-mt-2"
                    >
                      {item.address || <Empty />}
                    </p>
                  </div>
                </Card>
              </Col>

              <Col xl={18} lg={18} md={18} xs={24} sm={24}>
                <Card className="gx-student-seondCard-style ">
                  <Row>
                    {InstructorDetails("Full Name", item.fullName)}
                    {InstructorDetails("Email", item.email)}
                    {InstructorDetails("Mobile", item.phoneNumber)}
                    {InstructorDetails(
                      "Date",
                      moment(item.dateOfBirth).format("MM-DD-YYYY")
                    )}
                  </Row>

                  {instructorCertification("Education", item.education)}
                  {instructorCertification("Experience", item.experience)}
                </Card>
              </Col>
            </Row>
          );
        })}
    </Layout>
  );
};

export default ViewInstructor;
