import React from "react";
import Layout from "components/Layout";
import dummy from "assets/images/dummyImage.jpeg";
import { Button, Card } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
const ViewSupportSubject = () => {
  const history = useHistory();
  const subject = [
    {
      name: "Mike",
      date: "Aug 11 7:32 AM",
      para: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
    },
    {
      name: "Mike",
      date: "Aug 11 7:32 AM",
      para: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
    },
    {
      name: "Mike",
      date: "Aug 11 7:32 AM",
      para: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
    },
    {
      name: "Mike",
      date: "Aug 11 7:32 AM",
      para: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
    },
    {
      name: "Mike",
      date: "Aug 11 7:32 AM",
      para: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
    },
    {
      name: "Mike",
      date: "Aug 11 7:32 AM",
      para: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
    },
    {
      name: "Mike",
      date: "Aug 11 7:32 AM",
      para: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
    },
  ];
  return (
    <Layout>
      <Card>
        <div
          className="gx-d-flex gx-flex-column gx-justify-content-between"
          style={{ height: "80vh" }}
        >
          <div style={{ height: "auto" }}>
            <div
              className="gx-d-flex gx-align-items-center gx-justify-content-between"
              style={{ flexWrap: "wrap" }}
            >
              <div
                className="gx-d-flex gx-align-items-center "
                style={{ gap: "50px" }}
              >
                <span
                  style={{ fontSize: "20px" }}
                  lassName="gx-pointer"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ArrowLeftOutlined className="gx-pointer" />
                </span>
                <span style={{ fontSize: "16px" }}>
                  <span
                    style={{ fontSize: "20px" }}
                    className="gx-font-sans-bold  gx-mr-2"
                  >
                    Subject:
                  </span>{" "}
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </span>
              </div>
              <Button
                type="primary"
                className="gx-my-0 gx-gradient-button"
                style={{ width: "127px", height: "36px" }}
              >
                Resolved
              </Button>
            </div>

            <p
              className="gx-mt-2"
              style={{ border: "1px solid #cecece", width: "100%" }}
            />
          </div>

          <div
            className="gx-d-flex gx-flex-column gx-justify-content-between "
            style={{ height: "70vh", overflowX: "hidden" }}
          >
            {subject.map((item) => {
              return (
                <div className="gx-mb- gx-d-flex" style={{ gap: "20px" }}>
                  <img
                    style={{
                      width: "54px",
                      height: "54px",
                      borderRadius: "50%",
                    }}
                    src={dummy}
                    alt=""
                  />
                  <div className="gx-d-flex gx-flex-column  ">
                    <div className="gx-d-flex gx-flex-row gx-align-items-center gx-mb-2">
                      <h4 className="gx-mb-0 gx-mr-5">{item.name}</h4>
                      <span>{item.date}</span>
                    </div>
                    <p>{item.para}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div style={{ height: "auto" }}>
            <p
              className="gx-my-2"
              style={{ border: "1px solid #cecece", width: "100%" }}
            />
            <TextArea rows={5} />
            <span className="gx-d-flex gx-justify-content-end gx-mt-3">
              <Button
                type="primary"
                style={{ width: "127px", height: "36px" }}
                className="gx-my-0 gx-gradient-button"
              >
                Send
              </Button>
            </span>{" "}
          </div>
        </div>
      </Card>
    </Layout>
  );
};

export default ViewSupportSubject;
