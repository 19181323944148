import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import InstructorForm from "./instructorForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Form } from "antd";
const EditInstructor = () => {
  const [form] = Form.useForm(); 
  const [photoList, setPhotoState] = useState([]);
console.log("====photoList",photoList)
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_Instructor_MODAL, initialValues } = curdProps;
  

  useEffect(() => {
    if (initialValues) {
      setPhotoState(initialValues.profileImageUrl)
     
    }
  }, [initialValues]); 

 
  return (
    <div>
      <FormModal
        modalTitle={intlPlaceholder("Instructor.editPageTittle")}
        editApiName="editInstructor"
        recordName="instructorList"
        ModalName="EDIT_Instructor_MODAL"
        visible={EDIT_Instructor_MODAL}
        form={form}
        initialValues={initialValues}
        extraFieldName="profileImageUrl"
        extraFieldValue={photoList}
        width={650}
        isShowButton={false}
      >
        <InstructorForm setPhotoState={setPhotoState} photoList={photoList} form={form} />
      </FormModal>
    </div>
  );
};

export default EditInstructor;
