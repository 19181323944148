import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Button } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
const ViewQuiz = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const curdProps = useSelector((state) => state.CurdR);
  const { viewQuizzes_Modal, initialValues } = curdProps;
  const CloseModal = () => {
    dispatch({
      ModalName: "viewQuizzes_Modal",
      type: "Hide_Modal",
      payload: null,
    });
  };
  return (
    <SimpleModal
      width={530}
      title={<span className="gx-font-sans-bold">Attached Files</span>}
      modalFor="viewQuizzes_Modal"
      visible={viewQuizzes_Modal}
    >
      <Form form={form} layout="vertical" name="viewQuiz">
        <div>
          {initialValues &&
           initialValues.attachFileUrl &&
           initialValues.attachFileUrl.map((item, index) => {
              return (
                <div className="gx-d-flex gx-justify-content-between gx-pb-3">
                  <span>Attach File {index + 1} </span>
                  <span
                    onClick={() => window.open(item.imageUrl, "_blank")}
                    className="gx-pointer gx-link gx-text-blue"
                  >
                    View
                  </span>
                </div>
              );
            })}
        </div>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
            <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end gx-mr-3">
              <Form.Item className="gx-mb-0">
                <Button
                  type="default"
                  className="gx-pt-0 gx-mb-0 gx-mr-3"
                  htmlType="button"
                  onClick={() => CloseModal()}
                >
                  {intlPlaceholder("instructor.cancelbutton")}
                </Button>
              </Form.Item>
              <Form.Item className="gx-mb-0">
                <Button
                  type="primary"
                  className="gx-pt-0 gx-mb-0 gx-gradient-button"
                  htmlType="submit"
                  onClick={() => CloseModal()}
                >
                  OK
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </SimpleModal>
  );
};

export default ViewQuiz;
