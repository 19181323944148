import React, { useEffect, useState } from "react";
import SimpleModal from "components/Modal/SimpleModal";
import moment from "moment";
import { DataGetAction, DataRequestAction } from "redux/actions/CommonHttp";
import { Select, Form, DatePicker, Button, Spin } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
const AddSetting = () => {
  const [form] = Form.useForm();
  const TimeData = [
    {
      label: "08:00AM-09:00AM",
      key: "08:00AM-09:00AM",
      value: "08:00AM-09:00AM",
    },
    {
      label: "09:00AM-10:00AM",
      key: "09:00AM-10:00AM",
      value: "09:00AM-10:00AM",
    },
    {
      label: "10:00AM-11:00AM",
      key: "10:00AM-11:00AM",
      value: "10:00AM-11:00AM",
    },
    {
      label: "11:00AM-12:00PM",
      key: "11:00AM-12:00PM",
      value: "11:00AM-12:00PM",
    },
    {
      label: "12:00PM-01:00PM",
      key: "12:00PM-01:00PM",
      value: "12:00PM-01:00PM",
    },
    {
      label: "01:00PM-02:00PM",
      key: "01:00PM-02:00PM",
      value: "01:00PM-02:00PM",
    },
    {
      label: "02:00PM-03:00PM",
      key: "02:00PM-03:00PM",
      value: "02:00PM-03:00PM",
    },
    {
      label: "03:00PM-04:00PM",
      key: "03:00PM-04:00PM",
      value: "03:00PM-04:00PM",
    },
    {
      label: "04:00PM-05:00PM",
      key: "04:00PM-05:00PM",
      value: "04:00PM-05:00PM",
    },
    {
      label: "05:00PM-06:00PM",
      key: "05:00PM-06:00PM",
      value: "05:00PM-06:00PM",
    },
  ];
  const dispatch = useDispatch();
  const curdProps = useSelector((state) => state.CurdR);
  const { Setting_MODAL, meetingList, Loader, studentSuccess } = curdProps;
  const [Id, setId] = useState("");

  const onChange = (date) => {
    if (date) {
      dispatch(
        DataGetAction(
          "getDateRecord",
          "FetchRecord",
          { date: date._d },
          "StudentSpinner",
          "",
          "meetingList"
        )
      );
    }
  };

  useEffect(() => {
    if (meetingList && meetingList !== undefined) {
      if (meetingList && meetingList.length) {
        let id = meetingList[0]._id;
        setId(id);
        let time = meetingList[0].meetingTime;
        console.log("====time", time);
        form.setFieldsValue({ meetingTime: time });
      } else {
        form.resetFields(["meetingTime"]);
      }
    }
  }, [meetingList, form]);

  const Show = () => {
    form.resetFields();
    return dispatch({
      type: "Show_Modal",
      ModalName: "Setting_MODAL",
      payload: null,
    });
  };
  const CloseModal = () => {
    dispatch({
      ModalName: "Setting_MODAL",
      type: "Hide_Modal",
      payload: null,
    });
    form.resetFields();
  };

  const onFinish = (values) => {
    var type = "POST";
    var apiName = "addMeeting";
    var apiRequest = "AddRecord";

    if (meetingList && meetingList.length && meetingList !== undefined) {
      type = "PUT";
      apiName = "updateMeeting";
      apiRequest = "EditRecord";
    }
    dispatch(
      DataRequestAction(
        type,
        apiName,
        apiRequest,
        { id: Id, values },
        "StartSpinner",
        "Setting_MODAL",
        "settingsList"
      )
    );
    form.resetFields();
  };

  const renderList = () => {
    return (
      <>
        {studentSuccess && studentSuccess ? (
          <Spin className="gx-d-flex gx-align-items-center gx-justify-content-center"></Spin>
        ) : (
          !studentSuccess && (
            <>
              {" "}
              <Form.Item
                name="meetingTime"
                label={intlPlaceholder("setting.time")}
                rules={[
                  {
                    required: true,
                    message: intlPlaceholder("setting.message"),
                  },
                ]}
              >
                <Select mode="multiple" tokenSeparators={[" ", ","]}>
                  {TimeData.map((item) => {
                    return (
                      <Select.Option key={item.key} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          )
        )}
      </>
    );
  };

  const renderButton = () => {
    return (
      <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end ">
        <Form.Item className="gx-mb-0">
          <Button
            type="default"
            className="gx-pt-0 gx-m-0 gx-mr-3"
            htmlType="button"
            onClick={() => CloseModal()}
          >
            Cancel
          </Button>
        </Form.Item>

        {meetingList &&
        meetingList !== undefined &&
        meetingList.length &&
        meetingList.length > 0 ? (
          <Form.Item className="gx-mb-0">
            <Button
              type="primary"
              className="gx-pt-0 gx-mb-0 gx-gradient-button"
              htmlType="submit"
              disabled={Loader}
              loading={Loader}
            >
              Update
            </Button>
          </Form.Item>
        ) : (
          <Form.Item className="gx-mb-0">
            <Button
              type="primary"
              style={{ width: "80px" }}
              className="gx-pt-0 gx-mb-0 gx-gradient-button "
              htmlType="submit"
              disabled={Loader}
              loading={Loader}
            >
              Add
            </Button>
          </Form.Item>
        )}
      </div>
    );
  };

  return (
    <>
      <Button
        className="gx-pt-0 gx-m-0 gx-gradient-button"
        type="primary"
        onClick={() => Show()}
      >
        Add New
      </Button>

      <SimpleModal
        width={700}
        title={intlPlaceholder("setting.titlechange")}
        modalFor="Setting_MODAL"
        visible={Setting_MODAL}
      >
        <Form
          form={form}
          className="gx-px-5"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={intlPlaceholder("setting.date")}
            name="meetingDate"
            rules={[
              {
                required: true,
                message: intlPlaceholder("setting.messasge"),
              },
            ]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              onChange={onChange}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
              className="gx-w-100"
            />
          </Form.Item>
          {renderList()}
          {renderButton()}
        </Form>
      </SimpleModal>
    </>
  );
};

export default AddSetting;
