import React, { useRef, useEffect } from "react";
import Layout from "components/Layout";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { DataGetAction } from "redux/actions/CommonHttp";
import { Card, Button, Row, Col, Divider, Spin } from "antd";
import { PrinterOutlined, SendOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const ViewSalesReport = () => {
  const componentRef = useRef();
  console.log("===componentRef", componentRef);
  const params = useLocation();
  const dispatch = useDispatch();

  const { _id, studentId } = params && params.state;
  const curdProps = useSelector((state) => state.CurdR);
  const { salesDetailList, Loader } = curdProps;

  const fetchSalesData = () => {
    dispatch(
      DataGetAction(
        "getSalesReportDetails",
        "FetchRecord",
        { _id: _id, studentId: studentId },
        "StartSpinner",
        "",
        "salesDetailList"
      )
    );
  };
  useEffect(fetchSalesData, []);

  const studentDetail = (name, value) => {
    return (
      <>
        <Row>
          <Col xl={12} lg={12} md={12} xs={12} sm={12}>
            <span className="gx-font-sans-semibold gx-font-16">{name}</span>
          </Col>
          <Col xl={12} lg={12} md={12} xs={12} sm={12}>
            <span className="gx-font-sans-regular gx-text-center">{value}</span>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #D5D5D5" }} className="gx-mt-0 " />
      </>
    );
  };
  return (
    <Layout>
      {Loader && (
        <Spin className="gx-d-flex gx-align-items-center gx-justify-content-center"></Spin>
      )}

      {!Loader && salesDetailList && (
        <Card
          bodyStyle={{
            boxShadow: "12px -8px 6px #645D5D29",
            borderRadius: "10px",
            height: "100%",
          }}
          ref={componentRef}
        >
          <div className="gx-d-flex gx-flex-column ">
            <div
              style={{ flexWrap: "wrap" }}
              className="gx-d-flex gx-align-items-center gx-justify-content-between"
            >
              <h2 style={{ fontWeight: "bold" }}>Venters Tech Academy</h2>
              <div>
                <div className="gx-noprint">
                  <ReactToPrint
                    pageStyle="@page { size: auto; margin: 5mm; } }"
                    trigger={() => (
                      <Button
                        className="gx-pt-0"
                        style={{ backgroundColor: "#F4F5FA" }}
                      >
                        <PrinterOutlined />
                        Print Report
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />
                  <Button
                    style={{ width: "127px", backgroundColor: "#F4F5FA" }}
                  >
                    <SendOutlined />
                    Send Invoice
                  </Button>
                </div>
              </div>
            </div>

            <h4 className="gx-font-sans-semibold "> Invoice</h4>
            <Row>
              <Col xl={8} lg={8} md={12} xs={12} sm={12}>
                <p className="gx-font-sans-regular gx-d-flex gx-flex-start gx-text-capitalize">
                  {salesDetailList.adminAddress}
                </p>
              </Col>
              <Col xl={8} lg={8} md={0} xs={0} sm={0}></Col>
              <Col xl={8} lg={8} md={12} xs={12} sm={24}>
                {studentDetail("Student ID", salesDetailList.studentId)}
                {studentDetail("Student Name", salesDetailList.fullName)}
                {studentDetail(
                  "Purchase Date",
                  moment(salesDetailList.createdAt).format("MM-DD-YYYY")
                )}
              </Col>
            </Row>
            <Divider className="gx-mt-0" />
            <Row>
              <Col xl={8} lg={8} md={12} xs={12} sm={24}>
                <h4 className="gx-font-sans-semibold">Invoice to</h4>
                <p className="gx-text-start gx-text-capitalize">
                  {salesDetailList.studentAddress}
                </p>
              </Col>
              <Col xl={10} lg={10} md={0} xs={0} sm={0}></Col>

              <Col xl={6} lg={6} md={12} xs={10} sm={24}>
                <div className="gx-d-flex gx-flex-column ">
                  <h4 className="gx-font-sans-semibold">Bill to</h4>
                  <span className="gx-d-flex gx-justify-content-between">
                    Total Due: <span className="gx-text-center">$121278</span>
                  </span>
                </div>
              </Col>
            </Row>
            <Divider className="gx-mt-3" />
          </div>
          <Row>
            <Col xl={24} lg={24} md={24} xs={12} sm={12}>
              <Row className=" gx-align-items-center">
                <Col xl={6} lg={6} md={6} xs={24} sm={16}>
                  <h5 className="gx-font-sans-semibold">Course Name</h5>
                </Col>

                <Col xl={6} lg={6} md={6} xs={24} sm={16}>
                  <h5 className="gx-font-sans-semibold">Course Price</h5>
                </Col>

                <Col xl={6} lg={6} md={6} xs={24} sm={16}>
                  <h5 className="gx-font-sans-semibold">Remaining Balance</h5>
                </Col>

                <Col xl={6} lg={6} md={6} xs={24} sm={16}>
                  <h5 className="gx-font-sans-semibold">Purchase Date</h5>
                </Col>
              </Row>
            </Col>
            <Divider className="gx-mt-2 gx-d-md-block gx-d-none" />
            <Col xl={24} lg={24} md={24} xs={12} sm={12}>
              <Row>
                <Col xl={6} lg={6} md={6} xs={24} sm={8} className="gx-pb-2">
                  <span>{salesDetailList.courseName}</span>
                </Col>
                <Col xl={6} lg={6} md={6} xs={24} sm={8} className="gx-pb-2 ">
                  <span>${salesDetailList.coursePrice}</span>
                </Col>
                <Col xl={6} lg={6} md={6} xs={24} sm={8} className="gx-pb-2">
                  <span className="gx-text-center">
                    ${salesDetailList.balance}
                  </span>
                </Col>

                <Col xl={6} lg={6} md={6} xs={24} sm={8}>
                  <span className="gx-d-block gx-md-text-center">
                    {moment(salesDetailList.createdAt).format("MM-DD-YYYY")}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider className="gx-mt-2 gx-pb-5" />
          <div className="gx-mt-5">
            <h4 className="gx-ml-3">Note:</h4>
            <Row
              className="gx-flex-row gx-mx-auto gx-d-flex gx-align-items-center "
              style={{
                width: "97%",
                height: "55px",
                border: "0.5px solid #D5D5D5",
                borderRadius: "3px",
              }}
            >
              <span className="gx-ml-4">
                This is a computer generated invoice and does not need any
                signature
              </span>
            </Row>
          </div>
        </Card>
      )}
    </Layout>
  );
};

export default ViewSalesReport;
