import { Col, Row, Card } from "antd";
import React, { useEffect } from "react";
import ChartCard from "../ChartCard";
import RecentActivities from "components/ActivitiesList";
import PathYellow from "assets/images/Path.png";
import PathRed from "../../assets/images/Path38055.png";
import PathBlue from "assets/images/Path38118.png";
import PathGreen from "assets/images/Path38119.png";
import { FaUserGraduate } from "react-icons/fa"; 
import { GiTeacher } from "react-icons/gi";
import { FaCommentsDollar } from "react-icons/fa";
import { BiLibrary } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { DataGetAction } from "redux/actions/CommonHttp";
import MonthlyAnalysis from "./components/stackBar";
import Email from "./components/emailSend";
import WelcomeCard from "./components/WelcomeCard";
import DashboardList from "./components/instructorList";
import TranscationList from "./components/transcationList";
import DashBoardMettingCard from "./components/DashBoardMettingCard";

const Index = () => {
  const dispatch = useDispatch();
  const adminProps = useSelector((state) => state.CurdR);
  const { dashboardData , monthlyOverview} = adminProps;
  console.log("====dashboardData====", monthlyOverview);
  const fetchAdminDashboard = () => {
    dispatch(
      DataGetAction(
        "getAdminDashboardData",
        "FetchRecord",
        "",
        "gettingAdminDashboard",
        "",
        "dashboardData"
      )
    );
    dispatch(
      DataGetAction(
        "getMonthlyOverview",
        "FetchRecord",
        "",
        "StartSpinner",
        "",
        "monthlyOverview"
      )
    );
  };
  useEffect(fetchAdminDashboard, []);

  const cardTitls = (title) => {
    return (
      <span
        style={{ fontSize: "20px", color: "#272D3B" }}
        className="h4 gx-text-capitalize gx-mb-0 gx-font-sans-medium"
      >
        {title}
      </span>
    );
  };
  const history = useHistory();

  return (
    <div>
     
      <Row>
        <Col xl={17} lg={17} md={17} sm={17} xs={24}>
          <WelcomeCard 
          supportWaitingTickets={dashboardData && dashboardData.supportWaitingTickets}
          pendingMeetingRequests={dashboardData && dashboardData.pendingMeetingRequests}          
          totalActiveInstructor={dashboardData && dashboardData.totalActiveInstructor}
          />
        </Col>
        <Col xl={7} lg={7} md={7} sm={7} xs={24}>
          <DashBoardMettingCard
            upComingMeetings={dashboardData && dashboardData.upComingMeetings}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#E0ECFE"
            title={"Total Earnings"}
            icon={<FaCommentsDollar size={29} fill="#4791FF" />}
            count={dashboardData &&  `$${dashboardData.totalSales || 0} `}
            graph={<img src={PathBlue} alt="graph" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#FFF9E1"
            title={"Total Instructors"}
            icon={<GiTeacher size={29} fill="#FFD950" />}
            count={dashboardData && dashboardData.totalInstructor}
            graph={<img src={PathYellow} alt="graph" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#F9DAE5"
            title={"Total Students"}
            icon={<FaUserGraduate size={29} fill="#FF2366" />}
            count={dashboardData && dashboardData.totalStudent}
            graph={<img src={PathRed} alt="graph" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#D4F4E8"
            title={"Total Courses"}
            icon={<BiLibrary size={29} fill="#02BC77" />}
            count={dashboardData && dashboardData.totalCourses}
            graph={<img src={PathGreen} alt="graph" />}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={15} lg={15} md={15} sm={24} xs={24}>
          <Card
          
            title={cardTitls("New Admissions")}
            className="gx-card  gx-text-capitalize gx-mb-0 gx-font-sans-medium "
          >
          <MonthlyAnalysis
        data={monthlyOverview && monthlyOverview}
        width={"100%"}
        height={283}
        axisX={"name"}
        axisY={"Total Students"}
        showNumber={4}
        showYAxis={true}
        startColor={"#ffffff"}
        endcolor={"#51BDA7"}
        strokeColor={"#A2E2D9"}
      />
          </Card>
        </Col>

        <Col xl={9} lg={9} md={9} sm={24} xs={24}>
          <div className="recent-activity-container">
            <RecentActivities
              recentActivity={dashboardData && dashboardData.recentActivities}
              showImage={true}
              title="Activity Feed"
            />
          </div>
        </Col>
      </Row>
      <Card
        title={cardTitls("Instructor List")}
        className="gx-card h4 gx-text-capitalize  gx-font-sans-medium"
        extra={
          <span
            onClick={() => history.push("/instructor")}
            className="gx-d-none gx-d-sm-block gx-pointer gx-mb-0 gx-font-sans-bold gx-text-blue"
            style={{ fontSize: "17px", textDecoration: "underline" }}
          >
            View All
          </span>
        }
      >
        <DashboardList
          instructorList={dashboardData && dashboardData.instructorList}
        />
      </Card>
 
      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Email />
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Card
            bodyStyle={{ height: "461px" }}
            title={cardTitls("Recent Transactions")}
            className="gx-card h4 gx-text-capitalize gx-mb-0 gx-font-sans-medium"
            extra={
              <span
                onClick={() => history.push("/financial/salesReport")}
                className="gx-d-none gx-d-sm-block gx-pointer gx-mb-0 gx-font-sans-bold gx-text-blue"
                style={{ fontSize: "17px", textDecoration: "underline" }}
              >
                View All
              </span>
            }
          >
          
              <TranscationList
                recentTransactions={
                  dashboardData && dashboardData.recentTransactions
                }
              />
           
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
