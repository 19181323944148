import React from "react";
import CourseList from "components/Table/PageNationTable";
import DeleteStatus from "components/Modal/DeleteStatus";
import { renderStatus } from "utils/commonUseFunction";
//import { DataGetAction } from "redux/actions/CommonHttp";
import { Button } from "antd";
import moment from "moment";
//import { useDispatch } from "react-redux";
const CourseTable = ({ items, studentId }) => {
  const columns = [
    {
      title: "Course Name",
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.courseTitle || "----"}
        </span>
      ),
      key: "studentId",
      width: "100px",
    },

    {
      title: "Enrolled Date",
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.courseEnrolledDate).format("MM-DD-YYYY")}
        </span>
      ),
      key: "studentId",
      width: "100px",
    },
    {
      title: "Status",
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.courseStatusInfo) || "----"}
        </span>
      ),
      key: "courseStatusInfo",
      width: "100px",
    },
    {
      title: "",
      render: (record) => (
        <DeleteStatus
          record={{ courseId: record.courseId, studentId: studentId }}
          statusApiName="updateEnrolledCoursesRecordForStudent"
          recordName="studentDetailList"
          apiRequest="EditRecord"
          buttonName={"OK"}
          oKText={"OK"}
          title={"Are you sure you want to complete this courseStatus?"}
          content={"This course status will be completed permanently."}
          Icon={
            <Button
              style={{ height: "30px", borderRadius: "10px" }}
              disabled={record.courseStatusInfo === "completed" ? true : false}
              type="primary"
              className="gx-gradient-button gx-py-0-my-0 gx"
            >
              Mark as complete
            </Button>
          }
        />
      ),
      key: "studentId",
      width: "100px",
    },
  ];
  return (
    <CourseList
      sendApi={false}
      recordName="studentDetailList"
      columns={columns}
      tempData={items}
      scroll={true}
      pagination={true}
    />
  );
};

export default CourseTable;
