import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Auth from './Auth'
import Settings from './Settings'
import CurdReducer from './CRUDReducer'
import AdminDashboard from './AdminDashboard'
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    adminDashboard: AdminDashboard,
    CurdR: CurdReducer,
    
  })
