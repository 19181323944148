import React from "react";
import { Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
const Index = ({ menu }) => {
  return (
    <Dropdown
      overlayStyle={{
        boxShadow:
          "rgb(0 0 0 / 12%) 0px 3px 6px -4px, rgb(0 0 0 / 8%) 0px 6px 16px, rgb(0 0 0 / 5%) 0px 9px 28px",
        padding: 10,
        backgroundColor: "white",
      }}
      overlayClassName="table-dropdown"
      overlay={menu}
      trigger={["click"]}
    >
      <span
        className="gx-pointer gx-d-flex"
        style={{ fontSize: "20px", fontWeight: 700, color: "#595959" }}
      >
        <MoreOutlined className="tableActions" />
      </span>
    </Dropdown>
  );
};

export default Index;
