import React from "react";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Avatar,Empty } from "antd";
import { UserOutlined } from "@ant-design/icons";
import StudentList from "../../Table/List";
import { renderStatus } from "utils/commonUseFunction";
const TranscationsList = ({ recentTransactions }) => {
  const columns = [
    {
      title: intlPlaceholder("#"),
      render: (record) => (
        <span>
          <Avatar
            className="gx-mr-2"
            size={45}
            src={(record.profileImageUrl && record.profileImageUrl.length && record.profileImageUrl[0].imageUrl) || ""}
            icon={!record.profileImageUrl[0] && <UserOutlined />}
          />
        </span>
      ),
      key: "image",
    },

    {
      title: <span style={{whiteSpace:"nowrap"}}>{intlPlaceholder("order No")}</span>,
      render: (record) => <span>{record.orderId}</span>,
      key: "course",
      width: "100px",
    },
    {
      title: <span style={{whiteSpace:"nowrap"}}>{intlPlaceholder("Student Name")}</span>,
      render: (record) => <span>{record.fullName}</span>,
      key: "fullName",
      width: "300px",
    },
    
    {
      title: <span style={{whiteSpace:"nowrap"}}>{intlPlaceholder("Course")}</span>,
      render: (record) => <span>{record.courseTitle}</span>,
      key: "course",
      width: "300px",
    },
    

    {
      title: intlPlaceholder("Amount"),
      render: (record) => <span>${record.coursePrice}</span>,
      key: "amount",
    },
  ];

  return (
    <>
      {" "}
      {recentTransactions && recentTransactions.length > 0 ? (
        
        <StudentList
          columns={columns}
          scroll={true}
          pagination={true}
          tempData={recentTransactions}
        />
      
      ) : (
        <div className="gx-d-flex gx-align-items-center gx-justify-content-center gx-h-75">
          {" "}
          <Empty />
        </div>
      )}
    </>
  );
};

export default TranscationsList;
