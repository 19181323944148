import React, { useState } from "react";
import ReactQuil from "../../../components/ReactQuil";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { Form, Card, Input, Button, message } from "antd";

const Email = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [Details, setDetails] = useState();
  const curdProps = useSelector((state) => state.CurdR);
  const { emailSpinner, emailSuccess, emailFailure } = curdProps;
  console.log("====curdProps", curdProps);
  const onFinish = (value) => {
    const values = { ...value, Details };
    dispatch(
      DataRequestAction(
        "POST",
        "sendEmailByAdmin",
        "EmailSend",
        values,
        "EmailSpinner",
        "",
        "emailList"
      )
    );
  };
  if (emailSuccess) {
    message.success("Email sent successFully");
    dispatch({
      type: "ResetAll_State",
    });
    form.resetFields();
  }
  if (emailFailure) {
    message.success("error Occured");
    dispatch({
      type: "ResetAll_State",
    });
  }
  return (
    <div>
      <Card
        title={
          <span
            className="gx-pl-2 gx-pt-3 gx-font-sans-bold"
            style={{
              fontSize: "18px",
              textTransform: "capitalize",
              color: "#272D3B",
            }}
          >
            Email
          </span>
        }
        className="gx-card h4 gx-text-capitalize gx-mb-0 gx-font-sans-medium"
      >
        <Form form={form} className="gx-px-2 gx-pt-0" layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="toEmail"
            rules={[
              {
                required: true,
                message: "Please enter email!",
              },
              {
                type: "email",
                message: "Email is inCorrect!",
              },
            ]}
          >
            <Input addonBefore="To" />
          </Form.Item>
          <Form.Item
            name="subject"
            rules={[
              {
                required: true,
                message: "Please Fill the field!",
              },
            ]}
          >
            <Input addonBefore="Subject" />
          </Form.Item>

          <ReactQuil
            Form={Form}
            name="message"
            labelName={""}
            setValue={setDetails}
            value={Details}
          />

          <div className="gx-flex gx-flex-row gx-justify-content-end gx-pt-2 gx-pb-0">
            <Button
              style={{ width: "120px" }}
              type="primary"
              className="gx-py-0 gx-gradient-button "
              htmlType="submit"
              disabled={emailSpinner}
              loading={emailSpinner}
            >
              Send
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default Email;
