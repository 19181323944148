import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import EditCourse from "./components/editCourse";
import Widget from "components/Widget";
import CourseList from "./components/courseList";
import { DataGetAction } from "redux/actions/CommonHttp";
import { Spin, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ChartCourse from "./components/ChartCourse";

const Index = () => {
  const dispatch = useDispatch();
  const curdProps = useSelector((state) => state.CurdR);
  const { courseList, Loader } = curdProps;
  const totalNum = (courseList && courseList["total"]) || 0;
  const data=(courseList && courseList["tableData"]) || "";
  console.log("====courseLists",courseList,totalNum);
  const [filterValue, setFilterValue] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const [limit, setLimit] = useState(3);
  const getNextPageData = (page, pageSize) => {
    setPageNumber(page);
  };
  const getSizePageChangeData = (current, size) => {
    setLimit(size);
  };
  

  const fetchCourse = () => {
    dispatch(
      DataGetAction(
        "getAllInstructors",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "instructorList"
      )
    );
  };
  useEffect(fetchCourse, []);

  const fetchList = () => {
    let query = {
      query: "all",
      ...filterValue,
      pageNumber,
      limit,
    };
    dispatch(
      DataGetAction(
        "getCourse",
        "FetchTableRecord",
        query,
        "StartSpinner",
        "",
        "courseList"
      )
    );
  };
  useEffect(fetchList, [pageNumber, limit]);

  return (
    <Layout>
      <ChartCourse />
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        {Loader && (
          <Spin className="gx-d-flex gx-justify-content-center"></Spin>
        )}
        {!Loader && (
          <>
            {courseList !== undefined && (
              <CourseList courseList={data} />
            )}
          </>
        )}
        <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
        <Pagination
          pageSize={3} 
          total={totalNum}
          current={pageNumber}
          onChange={getNextPageData}
          onShowSizeChange={getSizePageChangeData}
        /></div>
       
      </Widget>
      <EditCourse />
    </Layout>
  );
};

export default Index;
 