import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import AssignmentForm from "./AssignmentForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Form } from "antd";

const EditAssignment = () => {
  const [form] = Form.useForm();
 
  const [photoList, setPhotoState] = useState([]);

  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_Assignment_MODAL, initialValues } = curdProps;
  useEffect(() => {
    if (initialValues) {
      setPhotoState(initialValues.attachFileUrl)
     
    } 
  }, [initialValues]); 

  const [id, setId] = useState("");

  return (
    <div> 
      <FormModal
        modalTitle={intlPlaceholder("Edit Assignment")}
        editApiName="editAssignment"
        recordName={"assignmentList"}
        ModalName="EDIT_Assignment_MODAL"
        visible={EDIT_Assignment_MODAL}
        form={form}
        initialValues={initialValues}
        width={650}
        isShowButton={false}
        extraFieldName="assignTo"
        extraFieldValue={id}
        secondExtraFieldName="attachFileUrl"
        secondExtraFieldValue={photoList}
      >
        <AssignmentForm
          setId={setId}
          setPhotoState={setPhotoState}
          photoList={photoList}
        />
      </FormModal>
    </div>
  );
};

export default EditAssignment;
