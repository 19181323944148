import React,{useState,useEffect} from "react";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import CourseForm from "./courseForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Form } from 'antd'
const EditCourse = () => {
  const [form] = Form.useForm();
  const [photoList, setPhotoState] = useState([]);
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_Course_MODAL, initialValues } = curdProps;
  console.log("=====initialValues",initialValues)
  
  useEffect(() => {
    if (initialValues) {
      setPhotoState(initialValues.courseImageUrl)
     
    }
  }, [initialValues]);   
 
  return (
    <div>
      <FormModal
        modalTitle={intlPlaceholder("Course.editPageTittle")}
        editApiName="editCourse"
        ModalName="EDIT_Course_MODAL"
        visible={EDIT_Course_MODAL}
        initialValues={initialValues}
        isShowButton={false}
        width={650}
        recordName={"courseList"}
        form={form}
        extraFieldName="courseImageUrl"
        extraFieldValue={photoList}
     
      >
        <CourseForm   setPhotoState={setPhotoState} photoList={photoList}  />
      </FormModal>
    </div>
  );
};

export default EditCourse;
