import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AvatarUploader from "components/AvatarUploader";
import ReactQuil from "../../../components/ReactQuil";
import { Input, Form, DatePicker, Select, Button } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import moment from "moment";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const StudentForm = ({ setPhotoState, photoList }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [mobileNumber, setmobileNumber] = useState();
  const [aboutMe, setaboutMe] = useState("");
  const curdProps = useSelector((state) => state.CurdR);
  const { initialValues } = curdProps;
  const readable = initialValues && initialValues !== null ? true : false;
  return (
    <div className="gx-px-5">
      <Form.Item
        label={intlPlaceholder("student.fullName")}
        name="fullName"
        rules={[
          {
            required: true,
            message: intlPlaceholder("student.fullNamemessage"),
          },
        ]}
      >
        <Input placeholder={intlPlaceholder("student.fullNameplaceholder")} />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("student.email")}
        name="email"
        rules={[
          {
            required: true,
            message: intlPlaceholder("student.email_message"),
          },
          {
            type: "email",
            message: intlPlaceholder("student.email_message"),
          },
        ]}
      >
        <Input
          disabled={readable}
          placeholder={intlPlaceholder("student.emailplaceholder")}
        />
      </Form.Item>
      {!initialValues && (
        <>
          <Form.Item
            label={intlPlaceholder("student.password")}
            name="password"
            rules={[
              {
                required: true,
                message: intlPlaceholder("student.passwordmessage"),
              },
            ]}
          >
            <Input.Password
              type="password"
              placeholder={intlPlaceholder("student.passwordPlaceholder")}
            />
          </Form.Item>

          <Form.Item
            className="gx-mt-2"
            label={intlPlaceholder("student.confirmpassword")}
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: intlPlaceholder("student.confirmpasswordmessage"),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Password did not match"));
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              placeholder={intlPlaceholder(
                "student.confirmPasswordPlaceholder"
              )}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        name="registrationDate"
        label={intlPlaceholder("student.registrationDate")}
        rules={[
          {
            required: true,
            message: intlPlaceholder("student.registrationDatemessage"),
          },
        ]}
      >
        <DatePicker
          format="MM-DD-YYYY"
          disabledDate={(current) => current.isAfter(moment().add())}
          className="gx-w-100"
        />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("student.gender")}
        name="gender"
        rules={[
          {
            required: true,
            message: intlPlaceholder("student.genderMessasge"),
          },
        ]}
      >
        <Select placeholder={intlPlaceholder("student.genderplaceholder")}>
          <Select.Option value="male">Male</Select.Option>
          <Select.Option value="female">Female</Select.Option>
          <Select.Option value="other">Others</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("student.number")}
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: intlPlaceholder("student.numberMessasge"),
          },
        ]}
      >
        <PhoneInput
          inputStyle={{
            width: "100%",
          }}
          country={"us"}
          value={phoneNumber}
          onChange={(phone) => setPhoneNumber({ phone })}
        />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("student.parentName")}
        name="parentName"
      >
        <Input placeholder={intlPlaceholder("student.parentNameplaceholder")} />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("student.parentnumber")}
        name="parentPhoneNumber"
      >
        <PhoneInput
          inputStyle={{
            width: "100%",
          }}
          country={"us"}
          value={mobileNumber}
          onChange={(phone) => setmobileNumber({ phone })}
        />
      </Form.Item>
      <Form.Item
        name="dateOfBirth"
        label={intlPlaceholder("student.birthDate")}
      >
        <DatePicker
          format="MM-DD-YYYY"
          disabledDate={(current) => current.isAfter(moment().add())}
          className="gx-w-100"
        />
      </Form.Item>
      <Form.Item label={intlPlaceholder("student.address")} name="address">
        <Input.TextArea
          placeholder={intlPlaceholder("student.addressplaceholder")}
        />
      </Form.Item>
      <Form.List name="education" initialValue={[""]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0)}
                label={index === 0 ? "Education" : "Education"}
                required={false}
                key={field.key + 1}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  noStyle
                >
                  <Input
                    placeholder="education"
                    style={{
                      width: "95%",
                    }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button gx-ml-1"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <span className="gx-d-flex gx-justify-content-end">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: "60%",
                  }}
                >
                  Add More Education
                </Button>
              </span>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.List name="certificate" initialValue={[""]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? "Cerificate" : "Certificate"}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  noStyle
                >
                  <Input
                    placeholder="certificate"
                    style={{
                      width: "95%",
                    }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button gx-ml-1"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <span className="gx-d-flex gx-justify-content-end">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: "60%",
                  }}
                >
                  Add More Certificate
                </Button>
              </span>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item name="photo" label={intlPlaceholder("student.picture")}>
        <AvatarUploader
         length={1}
          limit={0}
          list={[".png", ".jpeg", ".jpg"]}
          photoList={photoList}
          setPhotoState={setPhotoState}
        />
      </Form.Item>
      <ReactQuil
        Form={Form}
        name="aboutMe"
        labelName={"About Me"}
        setValue={setaboutMe}
        value={aboutMe}
      />
    </div>
  );
};

export default StudentForm;
