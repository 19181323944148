import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/FormModal";
import BannerFrom from "./bannerForm";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddBanner = () => {
  const [form] = Form.useForm();
  const { ADD_Banner_MODAL } = useSelector((state) => state.CurdR);

  const [photoList, setPhotoState] = useState([]);

  useEffect(() => {
    if (!ADD_Banner_MODAL) {
      setPhotoState([]);
    }
  }, [ADD_Banner_MODAL]);
  return (
    <FormModal
      addButtonName="Add Banner"
      modalTitle="Add New Banner"
      addApiName="addBanner"
      ModalName="ADD_Banner_MODAL"
      visible={ADD_Banner_MODAL}
      width={600}
      recordName="bannerList"
      form={form}
      extraFieldName="imageUrl"
      extraFieldValue={photoList}
    >
      <BannerFrom setPhotoState={setPhotoState} photoList={photoList} />
    </FormModal>
  );
};

export default AddBanner;
