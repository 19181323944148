import React,{useEffect, useState} from 'react'
import FormModal from 'components/Modal/FormModal'
import InstructorForm from './instructorForm'
import { intlPlaceholder } from 'util/intlPlaceholder'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
const AddInstructor = () => { 
  const [form] = Form.useForm()
  const { ADD_Instructor_MODAL } = useSelector((state) => state.CurdR)

  const [photoList, setPhotoState] = useState([]);

useEffect(()=>{
  if(!ADD_Instructor_MODAL){
    setPhotoState([])
  }
},[ADD_Instructor_MODAL])

 


  return ( 
    <FormModal 
      addButtonName={intlPlaceholder('Instructor.addInstructor')}
      modalTitle={intlPlaceholder('Instructor.addInstructorTitle')}
      submitButtonText={intlPlaceholder('Instructor.addbutton')}
      addApiName="addInstructor"
      ModalName="ADD_Instructor_MODAL"
      visible={ADD_Instructor_MODAL}
      width={650}
      recordName="instructorList"
      form={form}
      extraFieldName="profileImageUrl"
      extraFieldValue={photoList}
    >
      <InstructorForm setPhotoState={setPhotoState} photoList={photoList}/>
    </FormModal>
  )
}

export default AddInstructor