import React, { useEffect } from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import BannerList from "components/Table/List";
import Action from "../../components/Table/Actions/ActionDropdown";
//import TableHeader from "./components/TableHeader";
import ShowModal from "components/Modal/ShowModal";
import DeleteConfirm from "../../components/Modal/DeleteConfirm";
import { intlPlaceholder } from "util/intlPlaceholder";
import dummy from "../../assets/images/dummyImage.jpeg";
import { renderStatus } from "utils/commonUseFunction";
import moment from "moment/moment";
import { Image } from "antd";
import { useDispatch } from "react-redux";
import { DataRequestAction } from "../../redux/actions/CommonHttp";
import TableHeader from "./components/TableHeader";
import ViewDescription from "./components/ViewDescription";
import { DataGetAction } from "redux/actions/CommonHttp";
//import EditBanner from "./components/EditBanner";
//import ViewDescription from "./components/ViewDescription";

const Index = () => {
  const dispatch = useDispatch();

  const changeStatus = (id, status) => {
    dispatch(
      DataRequestAction(
        "Put",
        "updateBanner",
        "EditRecord",
        {
          _id: id,
          status: status,
        },
        "StartSpinner",
        "",
        "bannerList",
        `${status} Successfully`,
        ""
      )
    );
  };
  const renderActions = (record) => {
    console.log("===record==");
    const status = record.status === "activate" ? "deActivate" : "activate";
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          modalName="EDIT_BANNER_MODAL"
          buttonName="Edit"
          record={record}
        />
        <span
          className="gx-pointer"
          onClick={() => changeStatus(record._id, status)}
        >
          {record.status !== "activate" ? "Activate" : "De-Activate"}
        </span>

        <DeleteConfirm
          type="deleteBanner"
          apiRequest="DeleteRecord"
          recordName="bannerList"
          title="Are you sure you want to delete this user"
          content="You can't undo this action"
          selectedItem={record}
          Icon="Delete"
        />
      </div>
    );
  };
  const fetchBanner = () => {
    dispatch(
      DataGetAction(
        "getBanner",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "bannerList"
      )
    );
  };

  useEffect(fetchBanner, []);

  const columns = [
    {
      title: intlPlaceholder("banner.id"),
      render: (record) => <span>{record.bannerId}</span>,
      key: "bannerId",
    },
    {
      title: intlPlaceholder("banner.Profile"),
      render: (record) => (
        <div className="gx-d-flex gx-align-items-center" style={{ gap: "5px" }}>
          <Image
            src={record.profileImageUrl || dummy}
            alt=""
            width="40px"
            height="40px"
            style={{ borderRadius: "10px" }}
          />
        </div>
      ),
      key: "bannerProfile",
      width: "150px",
    },
    {
      title: intlPlaceholder("banner.bannerTitle"),
      render: (record) => <span>{record.bannerTitle}</span>,
      key: "bannerTitle",
      width: "200px",
    },
    {
      title: intlPlaceholder("banner.bannerSubTitle"),
      render: (record) => <span>{record.bannerSubTitle}</span>,
      key: "bannerSubTitle",
      width: "200px",
    },
    {
      title: intlPlaceholder("banner.description"),
      render: (record) => {
        return (
          <span className="gx-text-capitalize gx-link gx-text-blue">
            <span className="gx-text-capitalize gx-link   gx-overflow-ellipsis">
              {
                <ShowModal modalName={"ViewDescription_Modal"} record={record}>
                  {record.description
                    ? record.description.length > 40
                      ? record.description.slice(0, 30) + "...."
                      : record.description
                    : ""}
                </ShowModal>
              }
            </span>
          </span>
        );
      },
      key: "description",
      width: "200px",
    },
   
    {
      title: intlPlaceholder("banner.status"),
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },

    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];

  // const data = [
  //   {
  //     bannerId: "1",
  //     bannerProfile: "Hello",
  //     startDate: "MAR-12-22",
  //     endDate: "MAR-12-22",
  //     description:
  //       "Lorem Ipsum Dolor SIt AMet fsdgfsrjgighsrsdfjgodngangviuanghieghiunhbadifuhwsfriuhsiuhgaiduyhtubnujhysfbgu8y",
  //     views: "20",
  //     status: "active",
  //   },
  // ];
  return (
    <Layout>
      <Widget>
        <TableHeader />
        <BannerList
          apiName="getBanner"
          recordName="bannerList"
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>
      <ViewDescription />

      {/* <EditBanner /> */}
    </Layout>
  );
};

export default Index;
