import React,{useState} from "react";
import { Form, Input, Select } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import ReactQuil from "../../../components/ReactQuil";

const Role = [
  { value: "student", label: "Student" },
  {  value: "instructor",  label: "Instructor"  },
  { value: "studentAndInstructor", label: "Student And Instructor" },
];
const NoticeForm = () => {
  const [message, setMessage] = useState("");
  return (
    <div className="gx-px-5">
      <Form.Item
        label={intlPlaceholder("notice.title")}
        name="noticeTitle"
        rules={[
          {
            required: true,
            message: intlPlaceholder("quiz.message"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={intlPlaceholder("notice.select")}
        name="role"
        rules={[
          {
            required: true,
            message: intlPlaceholder("quiz.message"),
          },
        ]}
      >
        <Select>
          {Role.map((Info, index) => {
            return (
              <Select.Option key={index} value={Info.value}>
                {Info.label}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <ReactQuil
        Form={Form}
        name="message"
        labelName={intlPlaceholder("notice.textarea")}
        setValue={setMessage}
        value={message}
      />

    </div>
  );
};

export default NoticeForm;
