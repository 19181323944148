import React, { useEffect } from "react";
import moment from "moment";
import { Form, Input, DatePicker, Spin, message, Select } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import { DataGetAction } from "redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
const FinanceForm = ({ form, setID }) => {
  const Type = [
    { value: "deposit", label: "Deposit" },
    { value: "refund", label: "Refund" },
  ];

  const dispatch = useDispatch();

  const studentProps = useSelector((state) => state.CurdR);
  const { studentRecord, studentSuccess, initialValues } = studentProps;
  const readable = initialValues && initialValues !== null ? true : false;
  const onSearch = (value) => {
    if (value) {
      dispatch(
        DataGetAction(
          "getStudentByStudentId",
          "FetchRecord",
          { studentId: value },
          "StudentSpinner",
          "",
          "studentRecord"
        )
      );
    }
  };

  useEffect(() => {
    if (studentRecord && studentRecord !== undefined) {
      if (studentRecord && studentRecord.length) {
        let name = studentRecord[0].fullName;
        let depositAmount = studentRecord[0].accountBalance;
        let id = studentRecord[0]._id;
        setID(id);
        form.setFieldValue("fullName", name);
        form.setFieldValue("currentBalance", depositAmount);
      } else if (studentRecord && studentRecord.length === 0) {
        form.setFieldValue("fullName", "");
        return message.error(
          "Current Student Id is Invalid or Student is not active"
        );
      }
    } else {
      form.resetFields();
    }
  }, [studentRecord, form, setID]);

  return (
    <div className="gx-px-5">
      <Form.Item
        label={intlPlaceholder("finance.studentid")}
        name="studentId"
        rules={[
          {
            required: true,
            message: intlPlaceholder("financeStudentid.message"),
          },
        ]}
      >
        <Input.Search
          onSearch={onSearch}
          placeholder={intlPlaceholder("financeStudentid.placeholder")}
          type={"number"}
          min={0}
        />
      </Form.Item>

      {studentSuccess && studentSuccess ? (
        <Spin className="gx-d-flex gx-align-items-center gx-justify-content-center"></Spin>
      ) : (
        !studentSuccess && (
          <Form.Item
            label={intlPlaceholder("finance.Studentname")}
            name="fullName"

            // rules={[
            //   {
            //     required: true,
            //     message: intlPlaceholder("financeStudentname.message"),
            //   },
            // ]}
          >
            <Input
              disabled
              placeholder={intlPlaceholder("financeStudentname.placeholder")}
            />
          </Form.Item>
        )
      )}
      <Form.Item label={"Current Balance"} name="currentBalance">
        <Input disabled min={0} type="number" placeholder={"Current Balane"} />
      </Form.Item>
      <Form.Item
        label={"Transaction Type"}
        name="transactionType"
        rules={[
          {
            required: true,
            message: "Please enter Transaction Type!",
          },
        ]}
      >
        <Select placeholder={"Transaction Type"}>
          {Type.map((Info, index) => {
            return (
              <Select.Option key={index} value={Info.value}>
                {Info.label}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("finance.deposit")}
        name="depositAmount"
        rules={[
          {
            required: true,
            message: intlPlaceholder("financeDeposit.message"),
          },
        ]}
      >
        <Input
          min={0}
          type="number"
          placeholder={intlPlaceholder("financeDeposit.placeholder")}
        />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("finance.payment")}
        name="paymentMethod"
        initialValue={"Cash"}
        rules={[
          {
            required: true,
            message: intlPlaceholder("financePayment.message"),
          },
        ]}
      >
        <Input
          disabled
          placeholder={intlPlaceholder("financepayment.placeholder")}
        />
      </Form.Item>

      <Form.Item
        label={intlPlaceholder("finance.date")}
        name="createdAt"
        rules={[
          {
            required: true,
            message: intlPlaceholder("financeDate.message"),
          },
        ]}
      >
        <DatePicker
          format="MM-DD-YYYY"
          readOnly={readable}
          disabledDate={(current) => current.isAfter(moment().add())}
          className="gx-w-100"
        />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("finance.paymentdetail")}
        name="paymentDetails"
        rules={[
          {
            required: true,
            message: intlPlaceholder("financePaymentDetail.message"),
          },
        ]}
      >
        <Input.TextArea
          readOnly={readable}
          rows={4}
          placeholder={intlPlaceholder("financePaymentdetail.placeholder")}
        />
      </Form.Item>
    </div>
  );
};
export default FinanceForm;
