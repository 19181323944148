import React from "react";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import logo from "assets/images/LogoTransparency.png";
import AuthLeftSide from "../AuthLeftSide/AuthLeftSide";
import { Typography, Form, Input, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useHistory } from "react-router";
const { Title } = Typography;
const SetNewPassword = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const authProps = useSelector((state) => state.auth);
  const { SetSuccess, ShowSetNEwLoader, SetError } = authProps;
  const onFinish = (values) => {
    if (params && params.query !== "") {
      dispatch(
        DataRequestAction(
          "POST",
          "setNewPassword",
          "setNewPassword",
          { ...values, forgetPasswordAuthToken: params.query },
          "setNewPasswordSpin",
          "",
          ""
        )
      );
    }
  };
  if (SetSuccess) {
    message.success("Password updated successfully");
    history.push("/login");
    dispatch({
      type: "update_auth",
    });
  }
  if (SetError) {
    message.error("Some Error Occur Try Later");

    dispatch({
      type: "update_auth",
    });
  }
  const redirectToLogin = () => {
    history.push("/login");
  };
  return (
    <AuthLeftSide>
      
    <div
      className="gx-d-flex gx-flex-column gx-align-items-center gx-justify-content-center gx-h-100"
     
    >
      <div className="gx-text-center">
      <img src={logo} alt={"logo"} width={"85%"} />
        <Title
          className="gx-font-sans-bold gx-text-white gx-mb-0 gx-mt-5"
          level={2}
          
        >
          {intlPlaceholder("New.pageTitle")}
        </Title>
      </div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        className="gx-py-3 gx-d-flex gx-flex-column gx-w-75"
      >
        <Form.Item
          label={
          <span className="gx-text-white">
            {intlPlaceholder("instructor.password")}
          </span>
          }
          name="password"
          rules={[
            {
              required: true,
              message: intlPlaceholder("instructor.passwordmessage"),
            },
          ]}
        >
          <Input.Password
            type="password"
            placeholder={intlPlaceholder("instructor.passwordPlaceholder")}
          />
        </Form.Item>

        <Form.Item
          className="gx-mt-2"
          label={
            <span className="gx-text-white">
            {intlPlaceholder("instructor.confirmpassword")}
           </span>
          }
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: intlPlaceholder("instructor.confirmpasswordmessage"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Password did not match"));
              },
            }),
          ]}
        >
          <Input.Password
            type="password"
            placeholder={intlPlaceholder(
              "instructor.confirmPasswordPlaceholder"
            )}
          />
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          className="gx-w-100 gx-mt-4 gx-gradient-button"
          loading={ShowSetNEwLoader}
          disabled={ShowSetNEwLoader}
        >
          {intlPlaceholder("newPassword.sentBtn")}
        </Button>
        <p className="gx-text-center gx-font-sans-regular gx-font-16 gx-text-white">
        {intlPlaceholder("forgetPassword.messageplaceholder")}
          <span
                onClick={() => redirectToLogin()}
            style={{
              cursor: "pointer",
              color: "#C47CEF",
              marginLeft: "4px",
              
            }}
          >
          Login
          </span>
        </p>
      </Form>
    </div>
  
</AuthLeftSide>
  );
};

export default SetNewPassword;
