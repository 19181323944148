import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import QuizForm from "./quizForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Form } from "antd";

const EditQuiz = () => {
  const [form] = Form.useForm();

  const [photoList, setPhotoState] = useState([]);

  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_Quiz_MODAL, initialValues } = curdProps;

  useEffect(() => {
    if (initialValues) {
      setPhotoState(initialValues.attachFileUrl);
    }
  }, [initialValues]);

  const [id, setId] = useState("");
  return (
    <div>
      <FormModal
        modalTitle={intlPlaceholder("Quiz.editPageTittle")}
        editApiName="editQuiz"
        recordName={"quizList"}
        ModalName="EDIT_Quiz_MODAL"
        layout="vertical"
        visible={EDIT_Quiz_MODAL}
        form={form}
        initialValues={initialValues}
        width={650}
        isShowButton={false}
        extraFieldName="assignTo"
        extraFieldValue={id}
        secondExtraFieldName="attachFileUrl"
        secondExtraFieldValue={photoList}
      >
        <QuizForm
          setPhotoState={setPhotoState}
          photoList={photoList}
          setId={setId}
        />
      </FormModal>
    </div>
  );
};

export default EditQuiz;
