import React from "react";
import DateRangPicker from "components/Filters/DateRangPicker";
import AddNoticeboard from "../components/AddNoticeboard"
import { Typography } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import SelectFilter from "components/Filters/SelectFilter";
const { Title } = Typography;
const TableHeader = () => {
  return (
    <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-mb-3"
    style={{flexWrap:'wrap'}}>
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold gx-mb-2">
        {intlPlaceholder("noticeboard.pageTittle")}
      </Title>

      
      <div className="gx-d-flex gx-align-items-center"
       style={{ gap: "10px", flexWrap: "wrap" }}>
      <DateRangPicker
          customQuery={{ query: "date" }}
          apiName="getNoticeBoard"
          recordName="noticeBoardList"
          requestType="FetchTableRecord"
          width="220px"
        />
         <SelectFilter
          apiName="getNoticeBoard"
          recordName="noticeBoardList"
          requestType="FetchTableRecord"
          placeholder={"status"}
          searchKey="role"
          width={"150px"}
          isShowingAll={false}
          options={[
            { role: "student", label: "Student" },
            { role: "instructor", label: "Instructor" },
            { role: "studentAndInstructor", label: "Student And Instructor" },
          ]}
          ItemValue="role"
          label="label"
        />
         
           <AddNoticeboard />
        
      </div>
      </div>
  
  );
};

export default TableHeader;
