import React,{useState} from 'react'
import FormModal from 'components/Modal/FormModal'
import FinanceForm from './financialForm'
import { intlPlaceholder } from 'util/intlPlaceholder'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
const AddFinancial = () => {
    const [form] = Form.useForm()
    const { ADD_Finance_MODAL } = useSelector((state) => state.CurdR)

   
      const [id, setID] = useState();
   
    return (
        <FormModal 
            addButtonName={intlPlaceholder('financial.add')}
            modalTitle={intlPlaceholder('financial.titlechange')}
            addApiName="addFinancialSummary"
            ModalName="ADD_Finance_MODAL"
            addApiRequest="AddRecord"
            visible={ADD_Finance_MODAL}
            width={700}
            recordName={'financialSummaryList'}
            form={form}
            extraFieldName="studentAccountId"
            extraFieldValue={id}
        >
            <FinanceForm form={form}  setID={setID}/>
        </FormModal>
    )
}
export default AddFinancial;