import React from "react";
import SelectFilter from "components/Filters/SelectFilter";
import QuizForm from "./AddQuiz";
import SearchBox from "components/Filters/SearchBox";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Typography } from "antd";
const { Title } = Typography;
const TableHeader = ({setFilterValue}) => {
  return (
    <div 
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3 "
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold gx-mb-0">
        {intlPlaceholder("quiz.pagetitle")}
      </Title>

      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
       
       <SearchBox
           apiName="getQuiz"
           recordName="quizList"
           requestType="FetchTableRecord"
           width={"200px"}
          placeholder={intlPlaceholder("quiz.select")}
          customQuery={{ query: "search", key: "name" }}
          useSetFilterValue={true}
          setFilterValue={setFilterValue}
        />
        
        <SelectFilter
          apiName="getQuiz"
          recordName="quizList"
          requestType="FetchTableRecord"
          placeholder={intlPlaceholder("quiz.status")}
          searchKey="status"
          width={"200px"}
          isShowingAll={true}
          options={[
            { status: "active", label: "Active" },
            { status: "close", label: "Close" },
            { status: "delete", label: "Delete" },
          ]}
          ItemValue="status"
          label="label"
        />
        <QuizForm />
      </div>
    </div>
  );
};

export default TableHeader;
