import { Card } from "antd";
import React from "react";
import hoverImage from "assets/images/hover-state.png";
import DashboardImage from "../../../assets/images/Dashboard.svg";

const WelcomeCard = ({
  supportWaitingTickets,
  pendingMeetingRequests,
  totalActiveInstructor,
}) => {
  console.log(
    "======status",
    supportWaitingTickets,
    pendingMeetingRequests,
    totalActiveInstructor
  );
  const renderList = (value, title) => {
    return (
      <div
        className="gx-d-flex gx-align-items-center "
        style={{ gap: "7px", fontSize: "14px" }}
      >
        <span style={{ paddingBottom: "5px" }}>
          <i className="icon icon-circle" style={{ color: "#FF007C" }} />
        </span>
        <p>
          {value || 0}
          <span className="gx-pl-2">{title}</span>
        </p>
      </div>
    );
  };

  return (
    <Card className="gx-card">
      <div className="gx-d-flex gx-justify-content-between">
        <div
          style={{ marginTop: "-17px" }}
          className="gx-d-flex gx-flex-column "
        >
          <span
            style={{
              fontSize: "34px",
              fontFamily: "Nova Sans medium",
              paddingBottom: "20px",
              color: "#272D3B",
              whiteSpace: "nowrap",
            }}
          >
            Welcome John Dee!
          </span>

          {renderList(pendingMeetingRequests, "pending request for Meeting")}
          {renderList(totalActiveInstructor, "Total Active Instructor")}
          {renderList(supportWaitingTickets, "Support Tickets")}
        </div>
        <div
          style={{
            transition: "2s",
          }}
        >
          <img
            src={DashboardImage}
            onMouseOver={(e) => (e.currentTarget.src = hoverImage)}
            onMouseOut={(e) => (e.currentTarget.src = DashboardImage)}
            alt=""
            height={"170px"}
          />
        </div>
      </div>
    </Card>
  );
};

export default WelcomeCard;
