import { Col, DatePicker, Form, Input, Row } from "antd";
import React from "react";
import AvatarUploader from "components/AvatarUploader";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
const { TextArea } = Input;
const BannerForm = ({ setPhotoState, photoList }) => {
  console.log("====photoList==", photoList);
  return (
    <div className="gx-px-5">
      <Form.Item
        name="title"
        label={"Title"}
        rules={[
          {
            required: true,
            message: "field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="subTitle"
        label={"Sub Title"}
        rules={[
          {
            required: true,
            message: "field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="photo" label={intlPlaceholder("instructor.picture")}>
        <AvatarUploader
          length={1}
          limit={0}
          list={[".png", ".jpeg", ".jpg"]}
          photoList={photoList}
          setPhotoState={setPhotoState}
        />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <TextArea rows={5} />
      </Form.Item>
    </div>
  );
};

export default BannerForm;
