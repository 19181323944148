import React, { useState } from "react";
import FormModal from "components/Modal/FormModal";
import SalesForm from "./Salesform";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddSales = () => {
  const [form] = Form.useForm();
  const { ADD_Sales_MODAL } = useSelector((state) => state.CurdR);
  const [id, setID] = useState();
  return (
    <FormModal
      addButtonName={intlPlaceholder("sales.add")}
      modalTitle={intlPlaceholder("sales.titlechange")}
      addApiName="addFinanceSalesReport"
      ModalName="ADD_Sales_MODAL" 
      addApiRequest="AddRecord"
      visible={ADD_Sales_MODAL}
      width={700}
      recordName={"salesReportList"}
      form={form}
      extraFieldName="studentAccountId"
      extraFieldValue={id}
    >
      <SalesForm form={form} setID={setID} />
    </FormModal>
  );
};
export default AddSales;
