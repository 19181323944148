import React from "react";
import Layout from "components/Layout";
import Dashboard from "../../components/Dashboard";
const Index = () => {
  return (
    <Layout>
      <Dashboard />
    </Layout>
  );
};

export default Index;
