import React, { useState, useEffect } from "react";
import dummy from "assets/images/Background.jpg";
import ResetPassword from "./components/ResetPassword";
import AvatarUploader from "components/AvatarUploader/uploadPicture.js";
import Layout from "components/Layout";
import SettingForm from "./components/settingForm";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/CommonHttp";
import { Card, Spin, Avatar } from "antd";

const Index = () => {
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const styles = {
    img: {
      width: "180px",
      height: "180px",
      borderRadius: "50%",

      position: "absolute",
      bottom: "22px",
      left: "25px",
    },
  };
  const dispatch = useDispatch();
  const settingsProps = useSelector((state) => state.auth);
  const { settingsData, fetchingLoader } = settingsProps;

  const [photoList, setPhotoState] = useState(initialState);

  const renderImage = () => {
    if (
      settingsData &&
      settingsData.profileImageUrl &&
      settingsData.profileImageUrl.length > 0
    ) {
      if (settingsData.profileImageUrl[0].imageUrl) {
        return (
          <Avatar
            style={styles.img}
            src={settingsData.profileImageUrl[0].imageUrl}
          />
        );
      } else {
        return (
          <Avatar style={styles.img} src={settingsData.profileImageUrl[0]} />
        );
      }
    } else {
      return <Avatar style={styles.img} src={dummy} />;
    }
  };

  const fetchSettings = () => {
    dispatch(
      DataGetAction(
        "getProfile",
        "getProfile",
        "",
        "gettingProfile",
        "",
        "settingsData"
      )
    );
  };

  useEffect(fetchSettings, []);

  return (
    <Layout>
      <div
        className="gx-d-md-block gx-d-none"
        style={{ top: 0, padding: "5px" }}
      >
        <Card
          bodyStyle={{
            width: "100%",
            height: "418px",
            padding: "0",
            borderRadius: "8px",
            boxShadow: "0px 3px 16px #00000029",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "343px",
              padding: "0px",
              borderRadius: "8px",
              position: "relative",
            }}
            src={dummy}
            alt=""
          />

          <div className="gx-d-flex gx-align-items-center gx-justify-content-center gx-mb-0">
            <AvatarUploader
              setPhotoState={setPhotoState}
              photoList={photoList}
            />
            {renderImage()}
          </div>

          <div className="gx-d-flex gx-justify-content-end gx-pt-4 gx-pr-2">
            {" "}
            <ResetPassword />
          </div>
        </Card>
      </div>
      <Card
        className="gx-d-md-none gx-d-flex gx-flex-column gx-align-items-center gx-mx-3"
        bodyStyle={{
          width: "100%",
          height: "320px",
          padding: "0",
          borderRadius: "8px",
          boxShadow: "0px 3px 16px #00000029",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <AvatarUploader setPhotoState={setPhotoState} photoList={photoList} />
        <Avatar
          className="gx-mb-3"
          style={{ width: "200px", height: "200px", borderRadius: "100px" }}
          src={
            (settingsData &&
              settingsData.profileImageUrl &&
              settingsData.profileImageUrl.length &&
              settingsData.profileImageUrl[0]) ||
            dummy
          }
        />
        <ResetPassword />
      </Card>

      {fetchingLoader && <Spin></Spin>}
      {!fetchingLoader && settingsData !== undefined && (
        <SettingForm settingsData={settingsData && settingsData} />
      )}
    </Layout>
  );
};

export default Index;
