import React from "react";
import { Upload } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { DataRequestAction } from "redux/actions/CommonHttp";
const Index = ({ name, setPhotoState, photoList }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  // const { loading, imageUrl } = photoList

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setPhotoState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      dispatch(
        DataRequestAction(
          "PUT",     
          "updateProfile",
          "updateProfile",
          { profileImageUrl: { imageUrl: info.file.response.url} },
          "",
          "",
          ""
        )
      );
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      userInfo.profileImageUrl = info.file.response.url.location;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      setPhotoState({
        imageUrl: info.file.response.url,
        loading: false,
      });
    }
  };

  const ImageProps = {
    action: `${process.env.REACT_APP_ROOT_URL}/uploadFileToGoogleDrive`,
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: { name },
    onChange: handleChange,
    multiple: false,
    showUploadList: false,
  };

  return (
    <Upload {...ImageProps}>
      <div
        className="gx-d-flex gx-align-items-center gx-justify-content-center gx-pointer"
        style={{
          height: "20px",
          width: "38px",
          borderRadius: "50px",
          position: "absolute",
          bottom: "55px",
          left: "170px",
          zIndex:'1'
        }}
      >
        <CameraOutlined
          style={{ fontSize: "34px", color: "#48A996", overflow: "auto" }}
        />
      </div>
    </Upload>
  );
};

export default Index;
