import React from "react";
import frontLogin from "assets/images/login-for-skills.png";
import background from "assets/images/login-background.jpg";
import { Row, Col } from "antd";
const AuthLeftSide = ({ children }) => {
  return (
    <Row
      className="gx-px-0"
      style={{ background: "#0D0F12", height: "100vh", width: "100wh" }}
    >
      <Col xl={10} lg={11} md={24} xs={24} sm={24}>
        {children}
      </Col>

      <Col
        xl={14}
        lg={13}
        md={0}
        xs={0}
        sm={0}
        className="  gx-h-100   "
        style={{
          backgroundImage: `url(${background})`,
          scrollbarWidth: "none",
        }}
      >
        {" "}
        <div
          style={{ height: "100vh" }}
          className="gx-d-flex gx-align-items-center gx-justify-content-center  gx-h-100"
        >
          <img
            src={frontLogin}
            alt={"login background"}
            width={"60%"}
            height={"55%"}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AuthLeftSide;
