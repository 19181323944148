import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Row, Col, Form, Input, Button, Card, message } from "antd";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
const SettingForm = ({ settingsData }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const authProps = useSelector((state) => state.auth);
  const { profileLoader, profileEditeSuccess } = authProps;
  console.log("====settingsData", settingsData);
  const dispatch = useDispatch();
  const onFinish = (values) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "updateProfile",
        "updateProfile",
        values,
        "profileSpinner",
        "EDIT_PROFILE_MODAL",
        "settingsData"
      )
    );
  };
  if (profileEditeSuccess) {
    message.success("Edit Profile  successfully");
    dispatch({
      type: "reset_profilesettings",
    });
  }
  return (
    <div className="gx-px-3">
      <Card
        className="gx-mt-5"
        bodyStyle={{ boxShadow: "0px 3px 16px #00000029", borderRadius: "8px" }}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={settingsData && settingsData}
        >
          <Row className="gx-flex-row">
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={intlPlaceholder("settings.Name")}
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: intlPlaceholder("settingsName.message"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={intlPlaceholder("settings.Email")}
                name="email"
           
                rules={[
                  {
                    required: true,
                    message: intlPlaceholder("settingsEmail.message"),
                  },
                  {
                    type: "email",
                    message: intlPlaceholder("logIn.email_message"),
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row className="gx-flex-row">
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={intlPlaceholder("instructor.number")}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: intlPlaceholder("instructor.numberMessasge"),
                  },
                ]}
              >
                <PhoneInput
                  inputStyle={{
                    width: "100%",
                  }}
                  country={"us"}
                  value={phoneNumber}
                  onChange={(phone) => setPhoneNumber({ phone })}
                />
              </Form.Item>
            </Col>

            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={intlPlaceholder("settings.Adress")}
                name="address"
                rules={[
                  {
                    required: true,
                    message: intlPlaceholder("settingsAdress.message"),
                  },
                ]}
              >
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <span className="gx-d-flex gx-justify-content-end gx-mr-3 gx-mt-4">
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: "100px" }}
              loading={profileLoader}
              disabled={profileLoader}
            >
              Update
            </Button>
          </span>
        </Form>
      </Card>
    </div>
  );
};

export default SettingForm;
