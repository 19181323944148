import React, { useState } from "react";
import { intlPlaceholder } from "../../util/intlPlaceholder";
import { Upload, Button } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useDispatch } from "react-redux";
const Index = ({
  name,
  setPhotoState,
  photoList,
  list,
  limit,
  length,
  multiple = false,
}) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [success, setSuccess] = useState(intlPlaceholder("uploader.file"));
  const [loading, setLoading] = useState(false);

  const handleChange = (info) => {
    if (photoList && photoList.length <= limit) {
      if (info.file.size <= 1000000) {
        if (info.file.status === "uploading") {
          setSuccess("File is uploading");
          setLoading(true);
          return;
        }
        if (info.file.status === "done") {
          // Get this url from response in real world.
          setSuccess("File Uploaded Successfully");

          setPhotoState((list) => [
            ...list,
            { imageUrl: info.file.response.url, id: info.file.response.fileId },
          ]);
        }
      } else {
        setSuccess("file size can not be greater than 1mb");
        setLoading(false);
      }
    } else {
      setSuccess(`images can not be uploaded more than ${length}`);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div className="gx-d-flex ">
      <Button icon={<UploadOutlined />}>Choose File</Button>
      <span className="gx-mt-2">
        {photoList && photoList.length === length
          ? `images can not be uploaded more than ${length}`
          : success}
      </span>
    </div>
  );
  const onRemoveImageFormList = (item, index) => {
    dispatch(
      DataRequestAction(
        "DELETE",
        "deleteFileFromGoogleDrive",
        "DeleteRecord",
        { fileDetail: item },
        "",
        "",
        "deletedData"
      )
    );
    setPhotoState([
      ...photoList.slice(0, index),
      ...photoList.slice(index + 1, photoList.length),
    ]);
  };
  const ImageProps = {
    action:
      photoList && photoList.length <= limit
        ? `${process.env.REACT_APP_ROOT_URL}/uploadFileToGoogleDrive`
        : "",
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: { name },
    onChange: handleChange,
    multiple: multiple,
    showUploadList: false,
  };

  return (
    <>
      <Upload
        accept={list}
        disabled={photoList.length === length}
        {...ImageProps}
      >
        {uploadButton}
      </Upload>

      {photoList &&
        photoList.map((item, index) => {
          return (
            <div
              className="gx-mt-2 gx-p-2 gx-d-flex gx-align-items-center gx-justify-content-between"
              style={{ border: "1px dashed #D9D9D9", borderRadius: "5px" }}
            >
              <div>
                <img
                  style={{ borderRadius: "5px" }}
                  src={item.imageUrl}
                  alt=""
                  width="30px"
                  height="30px"
                />
                <span className="gx-ml-2">Attach File # {index + 1}</span>
              </div>
              <span
                onClick={() => {
                  onRemoveImageFormList(item, index);
                }}
                className="gx-text-red gx-pointer"
              >
                <DeleteOutlined />
              </span>
            </div>
          );
        })}
    </>
  );
};

export default Index;
