import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
 import AvatarUploader from "components/AvatarUploader";
import ReactQuil from "../../../components/ReactQuil";
import { Input, Form, DatePicker, Select, Button } from "antd";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import moment from "moment";
import { useSelector } from "react-redux";
import { MinusCircleOutlined } from "@ant-design/icons";
const InstructorForm = ({ setPhotoState, photoList }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [aboutMe, setaboutMe] = useState("");
  const curdProps = useSelector((state) => state.CurdR);
  const { courseList, initialValues } = curdProps;
  const readable = initialValues && initialValues !== null ? true : false;

  return (
    <div className="gx-px-5">
      <Form.Item
        label={intlPlaceholder("instructor.fullName")}
        name="fullName"
        rules={[
          { 
            required: true,
            message: intlPlaceholder("instructor.message"),
          },
        ]}
      >
        <Input placeholder={intlPlaceholder("InstructorName.placeholder")} />
      </Form.Item>

      <Form.Item
        label={intlPlaceholder("instructor.email")}
        name="email"
        rules={[
          {
            required: true,
            message: intlPlaceholder("instructor.email_message"),
          },
          {
            type: "email",
            message: intlPlaceholder("instructor.email_message"),
          },
        ]}
      >
        <Input
          disabled={readable}
          placeholder={intlPlaceholder("instructor.emailplaceholder")}
        />
      </Form.Item>

      <Form.Item
        label={intlPlaceholder("instructor.joiningDate")}
        name="createdAt"
        rules={[
          {
            required: true,
            message: intlPlaceholder("instructor.joiningdatemessage"),
          },
        ]}
      >
        <DatePicker
        format='MM-DD-YYYY'
          disabledDate={(current) => current.isAfter(moment().add())}
          className="gx-w-100"
        />
      </Form.Item>

      {!initialValues && (
        <>
          <Form.Item
            label={intlPlaceholder("instructor.password")}
            name="password"
            rules={[
              {
                required: true,
                message: intlPlaceholder("instructor.passwordmessage"),
              },
            ]}
          >
            <Input.Password
              type="password"
              placeholder={intlPlaceholder("instructor.passwordPlaceholder")}
            />
          </Form.Item>

          <Form.Item
            label={intlPlaceholder("instructor.confirmpassword")}
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: intlPlaceholder("instructor.confirmpasswordmessage"),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Password did not match"));
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              placeholder={intlPlaceholder(
                "instructor.confirmPasswordPlaceholder"
              )}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label={intlPlaceholder("instructor.designation")}
        name="designation"
        rules={[
          {
            required: true,
            message: intlPlaceholder("instructor.designationmessage"),
          },
        ]}
      >
        <Input
          placeholder={intlPlaceholder("Instructondesignation.placeholder")}
        />
      </Form.Item>

      <Form.Item label={intlPlaceholder("instructor.course")} name="courseName">
        <Select showSearch  placeholder={intlPlaceholder("Instructor.courseplaceholder")}>
          {courseList &&
            courseList.map((course) => {
              return (
                <Select.Option value={course.courseTitle}>
                  {course.courseTitle}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item label={intlPlaceholder("instructor.gender")} name="gender">
        <Select placeholder={intlPlaceholder("Instructor.genderplaceholder")}>
          <Select.Option value="male">Male</Select.Option>
          <Select.Option value="female">Female</Select.Option>
          <Select.Option value="other">Others</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={intlPlaceholder("instructor.number")}
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: intlPlaceholder("instructor.numberMessasge"),
          },
        ]}
      >
        <PhoneInput
          inputStyle={{
            width: "100%",
          }}
          country={"us"}
          value={phoneNumber}
          onChange={(phone) => setPhoneNumber({ phone })}
        />
      </Form.Item>

      <Form.Item
        name="dateOfBirth"
        label={intlPlaceholder("instructor.birthDate")}
      > 
        <DatePicker
        format='MM-DD-YYYY'
          disabledDate={(current) => current.isAfter(moment().add())}
          className="gx-w-100"
        />
      </Form.Item>

      <Form.Item label={intlPlaceholder("instructor.address")} name="address">
        <Input.TextArea
          placeholder={intlPlaceholder("Instructor.addressplaceholder")}
        />
      </Form.Item>
      <Form.Item name="photo" label={intlPlaceholder("instructor.picture")}>
        <AvatarUploader
        length={1}
        limit={0}
           list={[".png",".jpeg",".jpg"]}
        photoList={photoList} setPhotoState={setPhotoState} />
      </Form.Item>

      <Form.List name="education" initialValue={[""]}>
        {(fields, { add, remove }, { errors }) => (
          <>
          
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0)}
                label={index === 0 ? "Education" : "Education"}
                required={false}
                key={field.key + 1}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  noStyle
                >
                  <Input
                    placeholder="education"
                    style={{
                      width: "95%",
                    }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button gx-ml-1"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
              <Form.Item>
              <span className="gx-d-flex gx-justify-content-end">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: "60%",
                  }}
                 
                >
                  Add More Education
                </Button>
              </span>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.List name="experience" initialValue={[""]}>
        {(fields, { add, remove }, { errors }) => (
          <>
          
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? "Experience" : "Experience"}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  noStyle
                >
                  <Input
                    placeholder="experience"
                    style={{
                      width: "95%",
                    }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button gx-ml-1"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
              <Form.Item>
              <span className="gx-d-flex  gx-justify-content-end">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: "60%",
                  }}
                
                >
                  Add More Experience
                </Button>
              </span>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>

      <ReactQuil
        Form={Form}
        name="aboutMe"
        labelName={"About Me"}
        setValue={setaboutMe}
        value={aboutMe}
      />

    
    </div>
  );
};

export default InstructorForm;
