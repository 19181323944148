import React from "react";
import MenuList from "./Menu";
import IntlMessages from "../../../util/IntlMessages";
import SidebarLogo from "./SidebarLogo";
import UserInfo from "./userInfo";
import { Menu } from "antd";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const SubMenu = Menu.SubMenu;
const SidebarContent = ({drawerStyle}) => {
  const location = useLocation();
  const { pathname } = location;
  const logInUser = useSelector((state) => state.auth.authUser);
  const role = logInUser && logInUser.role;
  const MenuData = MenuList[role];
  const selectedKeys = pathname.substr(1);
  let keyArray = selectedKeys.split("/");
  let defaultOpenKeys = [keyArray[0]];
  if (selectedKeys.split("/").length === 3) {
    defaultOpenKeys = [keyArray[0], keyArray[1]];
  }

  const singleMenuItem = (Item) => {
    return (
      <Menu.Item 
      
        icon={
          Item.icon && (
            <span style={{ position: "relative", top: "4px" }}>
              {" "}
              {Item.icon}
            </span>
          )
        }
        key={Item.url}
      >
        <Link
          to={{ pathname: Item.url, state: { key: Item.key } }}
          key={Item.url + "key"}
        >
         
          <span>
            {<IntlMessages id={Item.title} />}
            {Item.badges ? console.log("=====") : ""}
          </span>
        </Link>
      </Menu.Item>
    );
  };

  const renderSubSubMenu = (Item) => {
    return (
      <SubMenu key={Item.key} title={<IntlMessages id={Item.title} />}>
        {Item.subMenu.map((x) => {
          return singleMenuItem(x);
        })}
      </SubMenu>
    );
  };

  const renderSubMenu = (Item) => {
    return (
      <SubMenu
        icon={Item.icon && <span> {Item.icon}</span>}
        key={Item.key}
        title={<IntlMessages id={Item.title} />}
      >
        {Item.subMenu.map((x) => {
          if (x.subMenu) {
            return renderSubSubMenu(x);
          } else {
            return singleMenuItem(x);
          }
        })}
      </SubMenu>
    );
  };

  const renderMenu = () => {
    return MenuData.map((Item) => {
      if (Item.subMenu) {
        return renderSubMenu(Item);
      } else {
        return singleMenuItem(Item);
      }
    });
  };
  console.log("===== default ====", defaultOpenKeys);
  return (
    <>
      <SidebarLogo />
      <div
        style={{ overflowX: "hidden" }}
        className="gx-sidebar-content gx-h-100 gx-pt-3"
      >
        <UserInfo drawerStyle={drawerStyle} />
        <Menu
          defaultOpenKeys={defaultOpenKeys}
          selectedKeys={["/" + selectedKeys]}
          theme="dark"
          mode="inline"
        >
          {renderMenu()}
        </Menu>
        <div style={{ height: "100px" }}></div>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
