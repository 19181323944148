import React from "react";
import AvatarUploader from "components/AvatarUploader";
import moment from "moment";
import { Form, Select, Input, Row, Col, DatePicker, InputNumber } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";

const QuizzForm = ({ setId, photoList, setPhotoState }) => {
  const studentProps = useSelector((state) => state.CurdR);

  const { courseTitles } = studentProps;

  const onChange = (e) => {
    console.log("=====e", e);
    courseTitles &&
      courseTitles.map((item) => {
        if (item._id === e) {
          setId(item.instructorId);
        }
      });
  };

  return (
    <div className="gx-px-3">
      <Row className="gx-flex-row">
        <Col lg={12}>
          <Form.Item
            label={intlPlaceholder("quiz.Title")}
            name="quizTitle"
            rules={[
              {
                required: true,
                message: intlPlaceholder("quiz.titlemessage"),
              },
            ]}
          >
            <Input placeholder={intlPlaceholder("quiz.Titleplaceholder")} />
          </Form.Item>
        </Col>

        <Col lg={12}>
          <Form.Item
            name="assignedCourseId"
            label={intlPlaceholder("quiz.course")}
            rules={[
              {
                required: true,
                message: intlPlaceholder("quiz.message"),
              },
            ]}
          >
            <Select onChange={onChange} showSearch placeholder="Select Course">
              {courseTitles &&
                courseTitles.map((course) => {
                  return (
                    <Select.Option value={course._id}>
                      {course.courseTitle}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row className="gx-flex-row">
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <Form.Item label={intlPlaceholder("quiz.Time")} name="quizTime">
            <Input placeholder="Leave it blank for Unlimited" />
          </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <Form.Item
            label={"Date"}
            name="quizDate"
            rules={[
              {
                required: true,
                message: intlPlaceholder("quiz.message"),
              },
            ]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
              className="gx-w-100"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="gx-flex-row">
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <Form.Item
            label={intlPlaceholder("quiz.totalMarks")}
            name="totalMarks"
            rules={[
              {
                required: true,
                message: intlPlaceholder("quiz.totalMarksMessage"),
              },
            ]}
          >
            <InputNumber
              min={0}
              type="number"
              placeholder="totalMarks"
              className="gx-w-100"
            />
          </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <Form.Item
            label={intlPlaceholder("quiz.passmarks")}
            name="passMarks"
            rules={[
              {
                required: true,
                message: intlPlaceholder("quiz.message"),
              },
            ]}
          >
            <InputNumber
              min={0}
              type="number"
              placeholder={intlPlaceholder("passing Marks")}
              className="gx-w-100"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="gx-flex-row">
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <Form.Item
            label={intlPlaceholder("quiz.attempts")}
            name="numberOfAttempts"
          >
            <Input placeholder="Leave it blank for Unlimited" />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <Form.Item
            label={intlPlaceholder("Total Questions")}
            name="noOfQuestions"
          >
            <Input
              min={0}
              type="number"
              placeholder="Leave it blank for Unlimited"
            />
          </Form.Item>
          <Form.Item name="photo" label={intlPlaceholder("quiz.picture")}>
            <AvatarUploader
              length={3}
              limit={2}
              list={[
                ".png",
                ".jpeg",
                ".jpg",
                ".pdf",
                ".xlx",
                ".xlsx",
                ".doc",
                ".docx",
                ".ppt",
                ".pptx",
              ]}
              photoList={photoList}
              setPhotoState={setPhotoState}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            label={intlPlaceholder("quiz.description")}
            name="description"
            rules={[
              {
                required: true,
                message: intlPlaceholder("Quiz.textAreamessage"),
              },
            ]}
          >
            <Input.TextArea rows={7} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default QuizzForm;
