import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import dummy from "assets/images/dummyImage.jpeg";
import { useSelector } from "react-redux";
import { Form } from "antd";

const ProfileForm = () => {
  const curdProps = useSelector((state) => state.CurdR);
  const { Title_Form_Modal, initialValues } = curdProps;
  console.log("initial====", initialValues);

  return (
    <>
      <SimpleModal
        width={530}
        title={
          <span className="gx-font-sans-medium gx-font-18 gx-text-capitalize">
            {initialValues && initialValues.fullName}
          </span>
        }
        modalFor="Title_Form_Modal"
        visible={Title_Form_Modal}
      >
        <Form layout="vertical" name="TitleForm">
          <div className="gx-d-flex  gx-flex-row ">
            <img
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
              }}
              src={(initialValues && initialValues.profileImageUrl.length &&initialValues.profileImageUrl[0].imageUrl) || dummy}
              alt=""
            />
            <div className="gx-d-flex gx-flex-column  gx-justify-content-center gx-ml-4">
              <span className="gx-font-sans-regular gx-font-14">
                {initialValues && initialValues.email}
              </span>
              <span className="gx-font-sans-regular gx-font-14">
                {initialValues && initialValues.phoneNumber}
              </span>
            </div>
          </div>
          <div className="gx-mt-3">
            <p className="gx-font-sans-regular gx-font-14">
              {initialValues && initialValues.meetingAgenda}
            </p>
          </div>
        </Form>
      </SimpleModal>
    </>
  );
};

export default ProfileForm;
