import React, { useEffect } from "react";
import { Layout } from "antd";
import {
  toggleCollapsedSideNav,
  onNavStyleChange,
} from "redux/actions/Setting";
import UserInfo from "components/UserInfo";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
const { Header } = Layout;

const Topbar = ({ transparent }) => {
  const { width, navCollapsed, navStyle, themeType } = useSelector(
    ({ settings }) => settings
  );
 
  useEffect(() => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));
  }, []);

  const dispatch = useDispatch();
  return (
    <Header>
      <div
        className={`gx-d-flex gx-justify-content-between gx-align-items-center gx-w-100 `}
      >
        <div className="gx-d-flex gx-align-items-center">
          {navStyle === NAV_STYLE_DRAWER ||
          ((navStyle === NAV_STYLE_FIXED ||
            navStyle === NAV_STYLE_MINI_SIDEBAR) &&
            width < TAB_SIZE) ? (
            <div className="gx-linebar gx-mr-2">
              <div className="gx-d-lg-none gx-d-flex gx-align-items-center">
                <i
                  className="gx-icon-btn icon icon-menu gx-text-primary"
                  onClick={() => {
                    dispatch(toggleCollapsedSideNav(!navCollapsed));
                  }}
                />
                <span style={{ fontSize: "14px" }} className="gx-text-primary">
                  Venters Tech
                </span>
              </div>
            </div>
          ) : null}

          {navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR ? (
            <div className="gx-linebar gx-d-lg-flex gx-d-none">
              <i
                style={{ width: "30px" }}
                className={`gx-icon-btn icon icon-${
                  navStyle === NAV_STYLE_MINI_SIDEBAR ? "menu" : "menu"
                }
                

                ${themeType !== THEME_TYPE_LITE ? "gx-text-black " : ""}`}
                onClick={() => {
                  if (navStyle === NAV_STYLE_DRAWER) {
                    dispatch(toggleCollapsedSideNav(!navCollapsed));
                  } else if (navStyle === NAV_STYLE_FIXED) {
                    dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
                  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                    dispatch(toggleCollapsedSideNav(!navCollapsed));
                  } else {
                    dispatch(onNavStyleChange(NAV_STYLE_FIXED));
                  }
                }}
              />
            </div>
          ) : null}
        </div>

        <div className="gx-header-notifications gx-d-flex gx-justofy-content-between gx-align-items-center  gx-p-2 gx-p-sm-0">
          <div className="gx-user-nav gx-align-items-center gx-d-flex">
            <UserInfo />
          </div>
        </div>
      </div>
    </Header>
  );
};

export default Topbar;
