import React from "react";
import moment from "moment";
import AvatarUploader from "components/AvatarUploader";
import { Form, Input, InputNumber, DatePicker, Select } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";

const Type = [
  { value: "home", label: "Home" },
  { value: "lab", label: "Lab" },
];

const AssignmentForm = ({ setId,photoList,setPhotoState }) => {
  const studentProps = useSelector((state) => state.CurdR);
  const { courseTitles } = studentProps;

  const onChange = (e) => {
    console.log("=====e", e);
    courseTitles &&
      courseTitles.map((item) => {
        if (item._id === e) {
          setId(item.instructorId);
        }
      });
  };

  return (
    <div className="gx-px-5">
      <Form.Item
        label={intlPlaceholder("Assignment Title")}
        name="assignmentTitle"
        rules={[
          {
            required: true,
            message: intlPlaceholder("Please enter Assignment Title!"),
          },
        ]}
      >
        <Input placeholder={intlPlaceholder("Assignment title")} />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("Course")}
        name="assignedCourseId"
        rules={[
          {
            required: true,
            message: intlPlaceholder("Please enter Course!"),
          },
        ]}
      >
        <Select onChange={onChange} showSearch placeholder="Select Course">
          {courseTitles &&
            courseTitles.map((course) => {
              return (
                <Select.Option value={course._id}>
                  {course.courseTitle}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item
        label={ "Type"}
        name="assignmentType"
        rules={[
          {
            required: true,
            message: intlPlaceholder("Please enter Assignment Type!"),
          },
        ]}
      >
        <Select placeholder={intlPlaceholder("Assignment Type ")}>
          {Type &&
            Type.map((item) => {
              return (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("Questions")}
        name="noOfQuestions"
        rules={[
          {
            required: true,
            message: intlPlaceholder("Please enter Questions!"),
          },
        ]}
      >
        <InputNumber
          min={0}
          type="number"
          placeholder={intlPlaceholder("Total Questions")}
          className="gx-w-100"
        />
      </Form.Item>

      <Form.Item
        label={intlPlaceholder("quiz.totalMarks")}
        name="totalMarks"
        rules={[
          {
            required: true,
            message: intlPlaceholder("quiz.totalMarksMessage"),
          },
        ]}
      >
        <InputNumber
          min={0}
          type="number"
          placeholder="Total Marks"
          className="gx-w-100"
        />
      </Form.Item>

      <Form.Item
        label={intlPlaceholder("Passing Marks")}
        name="passingMarks"
        rules={[
          {
            required: true,
            message: intlPlaceholder("quiz.message"),
          },
        ]}
      >
        <InputNumber
          min={0}
          type="number"
          placeholder={intlPlaceholder("passing Marks")}
          className="gx-w-100"
        />
      </Form.Item>

      <Form.Item
        label={"Due Date"}
        name="dueDate"
        rules={[
          {
            required: true,
            message: intlPlaceholder("quiz.message"),
          },
        ]}
      >
        <DatePicker
          format="MM-DD-YYYY"
          disabledDate={(current) =>
            current.isBefore(moment().subtract(1, "day"))
          }
          className="gx-w-100"
        /> 
      </Form.Item>
      <Form.Item name="photo" label={intlPlaceholder("quiz.picture")}>
            <AvatarUploader
            length={3}
            limit={2}
            list={[".png",".jpeg",".jpg",".pdf",".xlx",".xlsx",".doc",".docx",".ppt",".pptx"]}
              photoList={photoList}
              setPhotoState={setPhotoState}
            />
          </Form.Item>
    </div>
  );
};

export default AssignmentForm;
