import React from "react";
import { Timeline, Empty, Card } from "antd";
import moment from "moment";
import dummy from "assets/images/dummyImage.jpeg";


const Index = ({ recentActivity, showImage, title }) => {
  const renderNoDate = () => {
    return <Empty style={{ marginTop: "30px" }} />;
  };

  return (
    <Card
      title={
        <span
          className="h4"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
            color: "#272D3B",
          }}
        >
          {title}
        </span>
      }
      bodyStyle={{ height: "333px", overflowX: "hidden", overflowY: "auto" }}
      className="gx-card h4 gx-text-capitalize gx-mb-0 gx-font-sans-medium"
    >
      <div className="gx-timeline-info">
        {recentActivity && recentActivity.length > 0 ? (
          <Timeline>
            {showImage &&
              recentActivity.map((item) => {
                let time = moment(item.createdAt).fromNow(true);
                return (
                  <Timeline.Item
                    className="gx-d-flex"
                    mode="alternate"
                    dot={
                      <span className=" gx-size-50 ant-avatar-circle gx-mb-2">
                        <img
                          height={"50px"}
                          width={"50px"}
                          style={{ borderRadius: "10px", marginTop: "-13px" }}
                          src={item && item.profileImage && item.profileImage[0] && item.profileImage[0].imageUrl || dummy}
                          alt=""
                        />
                      </span>
                    }
                  >
                    <div className="gx-d-flex gx-flex-column ">
                      <span className="gx-font-sans-regular">
                        <span className="gx-font-sans-bold gx-mr-1">
                          {item.fullName}
                        </span>{" "}
                        <span
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </span>
                      <p style={{ color: "#707070" }}>{time}</p>
                    </div>
                  </Timeline.Item>
                );
              })}

            {!showImage &&
              recentActivity.map((item) => {
                return (
                  <Timeline.Item className="gx-d-flex" mode="alternate">
                    <div>
                      <span>
                        {" "}
                        <span className="gx-mr-1">{item.fullName}</span>{" "}
                        {item.desc}
                      </span>
                      <span>{item.createdAt}</span>
                    </div>
                  </Timeline.Item>
                );
              })}
          </Timeline>
        ) : (
          renderNoDate()
        )}
      </div>
    </Card>
  );
};

export default Index;
