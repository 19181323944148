import React, { useState } from "react";
import SettingsList from "components/Table/PageNationTable";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import moment from "moment";
import DeleteStatus from "components/Modal/DeleteStatus";
import { Tag, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";

const Settings = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState({});
  const CurdProps = useSelector((state) => state.CurdR);
  const { error,DeleteError,deleteMessage  } = CurdProps;
  


  if (error || DeleteError) {
    message.error(deleteMessage);
    dispatch({
      type: "ResetAll_State",
    });
  }
  const TimeSetting = (record) => {
    let meetingTime = record.meetingTime;
    let meetingDate = moment(record.meetingDate).format("MM-DD-YYYY");

    return meetingTime.map((item) => {
      let date = moment().format("MM-DD-YYYY");
      var timeRecord = meetingTime.filter((value) => {
        return value !== item;
      });
      console.log("====meetingDate",meetingDate,date , !moment(meetingDate).isBefore(date) )
      let RecordDetail = item;
      return (
        <div className="gx-position-relative">
          <Tag>{item}</Tag>
          { !moment(meetingDate).isBefore(date)&& (
            <div style={{ position: "absolute", right: "0px", bottom: "12px" }}>
              {timeRecord && timeRecord.length > 0 ? (
                <DeleteStatus
                  id={record._id}
                  statusApiName={"editMeeting"}
                  recordName="settingsList"
                  apiRequest="EditRecord"
                  Status={RecordDetail}
                  buttonName={intlPlaceholder("settings.buttonName")}
                  oKText={intlPlaceholder("settings.oKText")}
                  title={intlPlaceholder("settings.deleteTitle")}
                  content={intlPlaceholder("settings.deleteContent")}
                  Icon={
                    <CloseCircleOutlined className="gx-text-red gx-pointer" />
                  }
                />
              ) : (
                <DeleteStatus
                  id={record._id}
                  statusApiName={"editMeeting"}
                  recordName="settingsList"
                  Status={RecordDetail}
                  apiRequest="DeleteRecord"
                  buttonName={intlPlaceholder("settings.buttonName")}
                  oKText={intlPlaceholder("settings.oKText")}
                  title={intlPlaceholder("settings.deleteTitle")}
                  content={intlPlaceholder("settings.deleteContent")}
                  Icon={
                    <CloseCircleOutlined className="gx-text-red gx-pointer" />
                  }
                />
              )}
            </div>
          )}
        </div>
      );
    });
  };
  const columns = [
    {
      title: intlPlaceholder("settings.day"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.meetingDate).format("MM-DD-YYYY"  || "----")}
        </span>
      ),
      key: "day",
      width: "200px",
    },

    {
      title: intlPlaceholder("settings.time"),
      render: (record) => (
        <span className="gx-text-capitalize gx-d-flex gx-flex-row ">
          {TimeSetting(record)}
        </span>
      ),
      key: "time",
    },
  ];

  return (
    <Layout> 
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <SettingsList
          apiName="getMeetingSetting"
          recordName="settingsList"
          requestType="FetchRecord"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
    </Layout>
  );
};

export default Settings;
