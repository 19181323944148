import React, { useEffect, useState } from "react";
import { Table, message } from "antd";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
const Index = ({
  columns,
  scroll,
  pagination,
  apiName,
  recordName,
  sendApi = true,
  requestType = "FetchTableRecord",
  callOnCondition,
  customQuery = {},
  filterValue = {},
  tempData,
}) => {
  const dispatch = useDispatch();
  const CurdProps = useSelector((state) => state.CurdR);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);

  const getNextPageData = (page, pageSize) => {
    setPageNumber(page);
  };
  const getSizePageChangeData = (current, size) => {
    setLimit(size);
  };
  const fetchList = () => {
    let query = {
      query: "all",
      ...customQuery,
      ...filterValue,
      pageNumber,
      limit,
    };
    if (sendApi) {
      dispatch(
        DataGetAction(
          apiName,
          requestType,
          query,
          "StartSpinner",
          "",
          recordName
        )
      );
    }
  };
  useEffect(fetchList, [callOnCondition, pageNumber, limit]);

  const { success, error, FetchingSpinner } = CurdProps;

  if (success) {
    message.success(CurdProps.message);
    dispatch({
      type: "ResetAll_State",
    });
  }

  if (error) {
    message.error(CurdProps.message);
    dispatch({
      type: "ResetAll_State",
    });
  }

  const totalNum =
    (CurdProps[recordName] && CurdProps[recordName]["total"]) || 0;

  return (
    <Table
      className="BasicTable gx-table-responsive "
      columns={columns}
      dataSource={
        tempData
          ? tempData
          : CurdProps[recordName] && CurdProps[recordName]["tableData"]
      }
      scroll={scroll}
      pagination={{
        pageSize: 10,
        total: totalNum,
        current: pageNumber,
        onChange: getNextPageData,
        onShowSizeChange: getSizePageChangeData,
      }}
      loading={FetchingSpinner}
    />
  );
};

export default Index;
