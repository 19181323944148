import React from "react";
import AddCourse from "./addCourse";
import SearchBox from "components/Filters/SearchBox";
import SelectFilter from "components/Filters/SelectFilter";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Typography } from "antd";
const { Title } = Typography;
const TableHeader = ({ setFilterValue }) => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3 "
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-medium">
        {intlPlaceholder("course.pageTittle")}
      </Title>
      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
     

        <SearchBox
          apiName="getCourse"
          recordName="courseList"
          requestType="FetchTableRecord"
          width={"225px"}
          placeholder={intlPlaceholder("course.searchBox")}
          customQuery={{ query: "search", key: "name" }}
          useSetFilterValue={true}
          setFilterValue={setFilterValue}
        />
   <SelectFilter
          apiName="getCourse"
          recordName="courseList"
          requestType="FetchTableRecord"
          placeholder={intlPlaceholder("course.select")}
          searchKey="status"
          width={"200px"}
          isShowingAll={false}
          setFilterValue={setFilterValue}
          useSetFilterValue={true}
          customQuery={{ pageNumber: 1, limit: 3 }}
          options={[
            { status: "online", label: "Online" },
            { status: "offline", label: "Offline" },
          ]}
          ItemValue="status"
          label="label"
        />
        <AddCourse />
      </div>
    </div>
  );
};

export default TableHeader;
