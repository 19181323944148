import React from "react";
import ChartCard from "../../../components/ChartCard";
import PathRed from "assets/images/PathRed.png";
import PathBlue from "../../../assets/images/Path38118.png";
import PathGreen from "assets/images/Path38119.png";
import { GiTeacher } from "react-icons/gi";
import { useSelector } from "react-redux";

import { Col, Row } from "antd";
const Charts = () => {
 
  const curdProps = useSelector((state) => state.CurdR);
  const {  chartRecord } = curdProps;
  console.log("====chartRecord",chartRecord)


  return (
    <div>
      <Row className="gx-mb-2 gx-h-50">
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#EFD7FB"
            title={"Total Notices"}
            icon={<GiTeacher size={29} fill="#A212E8" />}
            count={(chartRecord && chartRecord.length && chartRecord[0].total) || 0}
            graph={<img src={PathBlue} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#D4F4E8"
            title={"Instructor Notices"}
            icon={<GiTeacher size={29} fill="#02BC77" />}
            count={(chartRecord && chartRecord.length && chartRecord[0].instructorNotices) || 0}
            graph={<img src={PathGreen} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#F9DAE5"
            title={"Student Notices"}
            icon={<GiTeacher size={29} fill="#FF2366" />}
            count={(chartRecord && chartRecord.length && chartRecord[0].studentNotices) || 0}
            graph={<img src={PathRed} alt="graph" />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Charts;
