import React from "react";
import { message, Modal} from "antd";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

const { confirm } = Modal;

const DeleteConfirm = ({
  id,
  _id="_id",
  studentId,
  title,
  content,
  buttonName,
  apiMethod = "PUT",
  apiRequest = "EditTableRecord",
  statusApiName,
  recordName,
  KeyObj="._id",
  Icon,
  oKText,
  customBtnClass,
  btnLabelSize = "20px",
  studentIdValue,
  record
}) => {
  const dispatch = useDispatch();
const deleteProps = useSelector((state) => state.CurdR)
const {DeleteError} = deleteProps
   if(DeleteError){
    message.error(deleteProps.message)
    dispatch({type:'ResetAll_State'})
  }
    
  const showDeleteConfirm = () => {
    confirm({
      title: title,
      icon: (
        <QuestionCircleOutlined
          className="gx-mt-2"
          style={{ fontSize: "220%" }}
        />
      ),
      content: content,
      okText: oKText || "Delete",
      okType: "danger",
      cancelText: "Cancel",
      confirmLoading: true,

      onOk() {
        dispatch(
          DataRequestAction(
            apiMethod,
            statusApiName,
            apiRequest,
            { [_id]: id, [ KeyObj]: studentId, [studentId]: studentIdValue,...record},
            "StudentSpinner",
            "",
            recordName
          )
        );
      },
      onCancel() {},
    });
  };

  return (
    <span
      className={` gx-pointer deleteButton ${customBtnClass} `}
      style={{ fontSize: btnLabelSize }}
      onClick={showDeleteConfirm}
    >
      {Icon ? (
        <span
          className="gx-font-gilory-regular gx-pointer"
          style={{
            fontSize: "14px",
            color: "#545454",
            verticalAlign: "top",
          }}
        >
          {Icon}
        </span>
      ) : (
        buttonName
      )}
    </span>
  );
};

export default DeleteConfirm;
