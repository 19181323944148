import React from "react";
import AddStudent from "./addStudent";
import SearchBox from "components/Filters/SearchBox";
import SelectFilter from "components/Filters/SelectFilter";
import { Typography } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
const { Title } = Typography;
const TableHeader = ({ setFilterValue }) => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3 "
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold gx-mb-0">
        {intlPlaceholder("student.pageTittle")}
      </Title>
      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
        <SearchBox
          apiName="getStudent"
          recordName="studentList"
          requestType="FetchTableRecord"
          width={"220px"}
          placeholder={intlPlaceholder("student.searchBox")}
          customQuery={{ query: "search", key: "name" }}
          useSetFilterValue={true}
          setFilterValue={setFilterValue}
        />
        <SelectFilter
          apiName="getStudent"
          recordName="studentList"
          requestType="FetchTableRecord"
          placeholder={intlPlaceholder("student.select")}
          searchKey="status"
          width={"200px"}
          isShowingAll={true}
          setFilterValue={setFilterValue}
          useSetFilterValue={true}
          customQuery={{ pageNumber: 1, limit: 10 }}
          options={[
            { status: "active", label: "Active" },
            { status: "block", label: "Block" },
            { status: "delete", label: "Deleted" },
          ]}
          ItemValue="status"
          label="label"
        />
        <AddStudent />
      </div>
    </div>
  );
};

export default TableHeader;
