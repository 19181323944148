import React from "react";
import FormModal from "components/Modal/FormModal";
import NoticeForm from "./noticeboardForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddNoticeboard = () => {
  const [form] = Form.useForm();
  const { ADD_Notice_MODAL } = useSelector((state) => state.CurdR);
  return (
    <FormModal
      addButtonName={intlPlaceholder("notice.add")}
      modalTitle={intlPlaceholder("notice.titlechange")}
      addApiName="addNoticeBoard"
      ModalName="ADD_Notice_MODAL"
      layout="vertical"
      visible={ADD_Notice_MODAL}
      width={600}
      recordName={"noticeBoardList"}
      form={form}
    >
      <NoticeForm />
    </FormModal>
  );
};
export default AddNoticeboard;
