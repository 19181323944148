import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard/index";
import Quizze from "./Quizze";
import Support from "./Support";
import NoticeBoard from "./Noticeboard";
import Setting from "./Setting";
import Instructor from "./instructor";
import Student from "./Student";
import Assignment from "./Assignments";
import Request from "./Meeting/Request";
import Settings from "./Meeting/settings";
import SalesReport from "./Financial/salesReport";
import FinancialSummary from "./Financial/financialSummary";
import Course from "./Courses";
import ViewStudent from "./Student/components/viewStudent";
import Notification from "./Notification";
import Banner from "./Banner";
import ViewSalesReport from "./Financial/salesReport/components/viewSalesReport";
import ViewSupportSubject from "./Support/components/viewSupportSubject";
import PageError from "./Pageerror";
import ViewInstructor from "./instructor/components/viewInstructor";
const App = ({ match }) => (
  <Switch>
    <Route path={`${match.url}dashboard`} component={Dashboard} />

    <Route path={`${match.url}banner`} component={Banner} />
    <Route path={`${match.url}quiz`} component={Quizze} />
    <Route path={`${match.url}support`} component={Support} />
    <Route path={`${match.url}noticeboard`} component={NoticeBoard} />
    <Route path={`${match.url}instructor`} component={Instructor} />
    <Route path={`${match.url}assignment`} component={Assignment} />
    <Route path={`${match.url}student`} component={Student} />
    <Route path={`${match.url}course`} component={Course} />
    <Route path={`${match.url}meeting/request`} component={Request} />
    <Route path={`${match.url}meeting/settings`} component={Settings} />
    <Route path={`${match.url}financial/salesReport`} component={SalesReport} />
    <Route path={`${match.url}financial/financialSummary`} component={FinancialSummary} />
    <Route path={`${match.url}viewStudent`} component={ViewStudent} />
    <Route path={`${match.url}viewInstructor`} component={ViewInstructor} />
    <Route path={`${match.url}viewSalesReport`} component={ViewSalesReport} />
    <Route
      path={`${match.url}viewSupportSubject`}
      component={ViewSupportSubject}
    />
     <Route path={`${match.url}notification`} component={Notification} />
    <Route path={`${match.url}setting`} component={Setting} />
    <Route path={`${match.url}*`} component={PageError} />
   
  </Switch>
);

export default App;
