import React, { useState, useEffect } from "react";
import InstructorList from "components/Table/PageNationTable";
import ActionDropdown from "components/Table/Actions";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import ShowModal from "components/Modal/ShowModal";
import EditInstructor from "./components/editInstructor";
import ResetPassword from "./components/resetPassword";
import moment from "moment";
import Charts from "./components/chartComponent";
import AssignedInstructor from "./components/assignedInstructor";
// import DeleteStatus from "components/Modal/DeleteStatus";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { intlPlaceholder } from "util/intlPlaceholder";
import { renderStatus } from "../../utils/commonUseFunction";
import { renderSubstring } from "../../utils/commonUseFunction";
import { DataGetAction, DataRequestAction } from "redux/actions/CommonHttp";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import phoneFormatter from "phone-formatter";
const Instructor = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState({});
  console.log("=====filterValue", filterValue);

  const fetchCourseData = () => {
    dispatch(
      DataGetAction(
        "getAvailableCourses",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "courseList"
      )
    );
    dispatch(
      DataGetAction(
        "getAdminAndAllInstructorList",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "assignedInstructorList"
      )
    );
  };
  useEffect(fetchCourseData, []);

  const resendInvite = (record) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "reinviteInstructor",
        "EditTableRecord",
        record,
        "StartSpinner",
        "",
        "instructorList"
      )
    );
  };

  const setUserStatus = (_id, status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "editInstructor",
        "EditTableRecord",
        { _id: _id, status: status },
        "StartSpinner",
        "",
        "instructorList"
      )
    );
  };

  const renderImage = (Image) => {
    if (Image && Image.profileImageUrl && Image.profileImageUrl.length > 0) {
      if (Image.profileImageUrl[0].imageUrl) {
        return (
          <Avatar
            className="gx-mr-2"
            size={45}
            src={Image.profileImageUrl[0].imageUrl}
          />
        );
      } else {
        return (
          <Avatar
            className="gx-mr-2"
            size={45}
            src={Image.profileImageUrl[0]}
          />
        );
      }
    } else {
      return <Avatar className="gx-mr-2" size={45} icon={<UserOutlined />} />;
    }
  };

  const viewInstructor = (record) => {
    return history.push({
      pathname: "/viewInstructor",
      state: { _id: record._id },
    });
  };
  const renderActions = (record) => {
    record.createdAt = record && moment(record.createdAt);
    record.dateOfBirth = record && moment(record.dateOfBirth);
    return (
      <div
        style={{ gap: "5px" }}
        className="gx-d-flex gx-flex-column popoverContent"
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            viewInstructor(record);
          }}
        >
          View
        </span>
        {record.status === "active" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "block")}
          >
            Block
          </span>
        )}
        {(record.status === "block" || record.status === "delete") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "active")}
          >
            Active
          </span>
        )}
        {(record.status === "pending" ||
          record.status === "invited" ||
          record.status === "reinvited") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => resendInvite(record)}
          >
            ReInvite
          </span>
        )}
        {record.status === "active" && (
          <ShowModal
            modalName={"AssignedInstructor_Modal"}
            buttonName="Assign Instructor"
            record={record}
          />
        )}
        {(record.status === "active" ||
          record.status === "invited" ||
          record.status === "reinvited") && (
          <ShowModal
            modalName={"EDIT_Instructor_MODAL"}
            buttonName="Edit"
            record={record}
          />
        )}
        {record.status === "active" && (
          <ShowModal
            modalName={"Reset_Password_Modal"}
            buttonName="Reset Password"
            record={record._id}
          />
        )}

        {/* {record.status === "block" && (
          <DeleteStatus
            id={record._id}
            Status="delete"
            statusApiName="editInstructor"
            recordName="instructorList"
            buttonName={intlPlaceholder("status.buttonName")}
            oKText={intlPlaceholder("status.oKText")}
            title={intlPlaceholder("status.deleteTitle")}
            content={intlPlaceholder("status.deleteContent")}
            Icon={intlPlaceholder("status.actionDeleteName")}
          />
        )} */}

        <DeleteConfirm
          type="deleteInstructor"
          recordName="instructorList"
          selectedItem={record}
          apiRequest="DeleteTableRecord"
          buttonName={intlPlaceholder("noticeBoard.buttonName")}
          oKText={intlPlaceholder("noticeBoard.oKText")}
          title={intlPlaceholder("noticeBoard.deleteTitle")}
          content={intlPlaceholder("noticeBoard.deleteContent")}
          Icon={intlPlaceholder("noticeBoard.actionDeleteName")}
        />
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("instructor.ID"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.instructorId || "----"}
        </span>
      ),
      key: "instructorId",
      width: "100px",
    },
    {
      title: intlPlaceholder("instructor.FullName"),
      render: (record) => (
        <span className="gx-d-flex gx-align-items-center">
          {renderImage(record)}
          <span
            className="gx-pointer gx-text-blue gx-link"
            onClick={() => {
              viewInstructor(record);
            }}
          >
            {" "}
            {renderSubstring(record.fullName || "----", "30", "30")}{" "}
          </span>
        </span>
      ),
      key: "fullName",
    },
    {
      title: intlPlaceholder("instructor.Email"),
      render: (record) => <span>{record.email || "----"}</span>,
      key: "email",
    },
    {
      title: intlPlaceholder("instructor.PhoneNumber"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.phoneNumber
            ? phoneFormatter.format(record.phoneNumber, "(NNN) NNN-NNNN", {
                normalize: false,
              })
            : "----"}
        </span>
      ),
      key: "phoneNumber",
    },
    {
      title: intlPlaceholder("instructor.Status"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status)}
        </span>
      ),
      key: "status",
    },
    {
      title: intlPlaceholder("instructor.Courses"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.courseName || "----"}
        </span>
      ),
      key: "courses",
    },
    {
      title: intlPlaceholder("instructor.date"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.createdAt).format("MM-DD-YYYY") || "----"}
        </span>
      ),
      key: "date",
    },

    {
      title: "",
      render: (record) => (
        <ActionDropdown>{renderActions(record)}</ActionDropdown>
      ),
      key: "action",
      width: "50px",
    },
  ];

  return (
    <Layout>
      <Charts />
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <InstructorList
          apiName="getInstructorList"
          recordName="instructorList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
      <ResetPassword />
      <EditInstructor />
      <AssignedInstructor />
    </Layout>
  );
};

export default Instructor;
