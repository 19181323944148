import React, { useEffect } from "react";
import { Avatar } from "antd";
import dummy from "assets/images/dummyImage.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/CommonHttp";
const UserInfo = ({ drawerStyle }) => {
  const dispatch = useDispatch();
  const settingsProps = useSelector((state) => state.auth);
  const { settingsData } = settingsProps;

  const renderImage = () => {
    if (
      settingsData &&
      settingsData.profileImageUrl &&
      settingsData.profileImageUrl.length > 0
    ) {
      if (settingsData.profileImageUrl[0].imageUrl) {
        return (
          <Avatar
            style={{ width: "100%", height: "100%" }}
            src={settingsData.profileImageUrl[0].imageUrl}
          />
        );
      } else {
        return (
          <Avatar
            style={{ width: "100%", height: "100%" }}
            src={settingsData.profileImageUrl[0]}
          />
        );
      }
    } else {
      return <Avatar style={{ width: "100%", height: "100%" }} src={dummy} />;
    }
  };

  const fetchSettings = () => {
    dispatch(
      DataGetAction(
        "getProfile",
        "getProfile",
        "",
        "gettingProfile",
        "",
        "settingsData"
      )
    );
  };

  useEffect(fetchSettings, []);

  return (
    <>
      <div className="gx-d-flex gx-align-items-center gx-flex-column gx-justify-content-center gx-mt-3  gx-mb-3">
        {!drawerStyle && (
          <div
            className="gx-mb-4"
            style={{
              overflow: "hidden",
              borderRadius: "50%",
              border: "6px solid #D99FEB",
              width: "151px",
              height: "151px",
            }}
          >
           {renderImage()}
          </div>
        )}
        {drawerStyle && (
          <div
            className="gx-mb-4"
            style={{
              overflow: "hidden",
              borderRadius: "50%",
              border: "6px solid #D99FEB",
              width: "60px",
              height: "60px",
            }}
          >
           {renderImage()}
          </div>
        )}
        <span
          className="gx-font-sans-bold"
          style={{ color: "#545454", fontSize: "16px" }}
        >
          {(settingsData && settingsData.fullName) || ""}
        </span>
      </div>
    </>
  );
};

export default UserInfo;
