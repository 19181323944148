import React from "react";
import ChartCard from "../../../components/ChartCard";
import PathRed from "assets/images/PathRed.png";
import PathBlue from "assets/images/Path38118.png";
import PathGreen from "assets/images/Path38119.png";
import { MdQuiz } from "react-icons/md";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";

const ChartQuiz = () => {
 
  const curdProps = useSelector((state) => state.CurdR);
  const {  chartRecord } = curdProps;
  console.log("====chartRecord",chartRecord)

  return (
    <div>
      <Row className="gx-mb-2 gx-h-50">
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#EFD7FB"
            title={"quizCard1.Title"}
            count={(chartRecord && chartRecord.length &&  chartRecord[0].total) || ""}
            icon={<MdQuiz size={29} fill="#A212E8" />}
            graph={<img src={PathBlue} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#D4F4E8"
            title={"quizCard2.Title"}
            count={(chartRecord && chartRecord.length &&  chartRecord[0].totalActive) || ""}
            icon={<MdQuiz size={29} fill="#02BC77" />}
            graph={<img src={PathGreen} alt="graph" />}
          />
        </Col>

        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#FADADC"
            title={"quizCard3.Title"}
            count={(chartRecord && chartRecord.length &&  chartRecord[0].totalClose) || ""}
            icon={<MdQuiz size={29} fill="#FF2366" />}
            graph={<img src={PathRed} alt="graph" />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChartQuiz;
