import React from "react";
import { Popover } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
const Index = ({ children }) => {
  const content=( <div style={{gap:"20px"}} className="gx-d-flex gx-align-items-center gx-flex-column ">{children} </div>)
  return (
    <Popover placement="leftBottom" content={content} title="" trigger="focus">
      <button className="gx-pointer" style={{
          background: "none",
          padding: "0px",
          boxShadow: "none",
          border: "none",
        }}>  <EllipsisOutlined  style={{fontSize:"28px"}}/></button>
     
    </Popover>
  );
};

export default Index;
