import React from 'react'
import { Button, Modal, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { DataRequestAction } from '../../redux/actions/CommonHttp'

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  modalTitle,
  submitButtonText = 'Save',
  cancelButtonText = 'Cancel',
  children,
  width,
  layout,
  className,
  showFooter,
  form,
}) => {
  const CrudProps = useSelector((state) => state.CurdR)
  const { Loader } = CrudProps
  if (!visible) {
    form.resetFields()
  }
  const formItemLayout =
  layout === 'horizontal'
    ? {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      }
    : null;
  return (
    <div>
      <Modal
        visible={visible}
        title={modalTitle}
        width={width}
        form={form}
        bodyStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        okText={submitButtonText}
        cancelText={cancelButtonText}
        className={className}
        onCancel={() => {
          form.resetFields()
          onCancel()
        }}
        okButtonProps={{ disabled: showFooter }}
        confirmLoading={Loader}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values)
            })
            .catch((info) => { })
        }}
      >
        <Form form={form} 
        {...formItemLayout}
          layout={layout} name="form_in_modal">
          {children}
        </Form>
      </Modal>
    </div>
  )
}

const CollectionsPage = ({
  children,
  modalTitle,
  submitButtonText,
  cancelButtonText,
  apiMethod = 'POST',
  addApiName,
  addApiRequest = 'AddTableRecord',
  visible,
  ModalType = 'Hide_Modal',
  width,
  className,
  apiLoader = 'StartSpinner',
  addButtonShow = true,
  extraFieldName,
  secondExtraFieldName,
  secondExtraFieldValue,
  layout="horizontal",
  extraFieldValue,
  payload = null,
  addButtonName = 'Add',
  showFooter,
  ModalName, 
  recordName,
  textMessage,
  disabled = false,
  form,
}) => {
  const dispatch = useDispatch()
  const CrudProps = useSelector((state) => state.CurdR)
  const { Loader } = CrudProps
  const onCreate = (values) => {
   
   
    if (values.email) {
      values.email = values.email.toLowerCase()
    }

    if (extraFieldName) {
      values[extraFieldName] = extraFieldValue
    }
    if (secondExtraFieldName) {
      values[secondExtraFieldName] = secondExtraFieldValue
    }

    dispatch(
      DataRequestAction(
        apiMethod,
        addApiName,
        addApiRequest,
        values,
        apiLoader,
        ModalName,
        recordName
      )
    )
  }

  return (
    <div>
      {addButtonShow && (
        <Button
          className="gx-pt-0 gx-m-0 gx-gradient-button"
          type="primary"
          onClick={() => {
            form.resetFields();
            dispatch({
              type: 'Show_Modal',
              payload: payload,
              ModalName: ModalName,
            })
            
          }}
        >
          {addButtonName}
        </Button>
      )}
      <CollectionCreateForm
        modalTitle={modalTitle}
        submitButtonText={submitButtonText}
        cancelButtonText={cancelButtonText}
        apiMethod={apiMethod}
        ModalType={ModalType}
        confirmLoading={Loader}
        layout={layout}
        apiLoaderName={apiLoader}
        visible={visible}
        width={width}
        className={className}
        showFooter={showFooter}
        onCreate={onCreate}
        form={form}
        onCancel={() => {
          dispatch({
            type: ModalType,
            payload: null,
            ModalName: ModalName,
            clear: true,
          })
        }}
      >
        {children}
      </CollectionCreateForm>
    </div>
  )
}

export default CollectionsPage
