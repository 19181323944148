import { updateProfile } from "./CommonFn";
const INIT_STATE = {
  token: localStorage.getItem("token"),
  initURL: "",
  authUser: JSON.parse(localStorage.getItem("userInfo")),
  loginStart: false,
  success: false,
  error: false,
  resetSuccess: false,
  resetFail: false,
  unAuthorized_modal: false,
  message: "",
  otpSuccess: false,
  otpLoader: false,
  ResetLoading: false,
  ShowSetNewLoader: false,
  ShowForgotLoader: false,
  userInfo: null,
  userRegisterSuccessfully: false,
  userRegisterFail: false,
  savingDetail: false,
  savingLoginDetail: false,
  loginDetailChangeSuccessfully: false,
  loginDetailChangeFail: false,
  loginFail: false,
  loginFailMessage: "",
  loginSuccess: false,
  logoutSuccess: false,
  ShowRestLoader: false,
  TokenSaveSuccess: false,
  profileLoader: false,
  changePasswordModelVisible: false,
  editProfileModalVisible: false,
  planSettingsCompleted: false,
  isPasswordChanged: false,
  planeSettings: [],
  textMessage: "",
  isPlanSettingsFail: false,
  isPlanSettingsUpdated: false,
  settingsData: [],
  fetchingLoader: false,
  fetchLoader: false,
  profileEditeSuccess: false,
  updatingProfile: false,
  updateSettingSuccess: false,
  SettingMessage: "",
  updateSettingError: false,
  updateSpinner: false,
  ForSuccess: false,
  ForError: false,
  SetSuccess: false,
  SetError: false,
  ShowSetNEwLoader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "LoginSpinner_START": {
      return { ...state, loginStart: true };
    }
    case "forgetPassword_SUCCESS": {
      return {
        ...state,
        ForSuccess: true,
        ShowForgotLoader: false,
      };
    }
    case "forgetPassword_FAILURE": {
      return {
        ...state,
        ForError: true,
        ShowForgotLoader: false,
      };
    }
    case "forgot_START": {
      return {
        ...state,
        ShowForgotLoader: true,
      };
    }
    case "setNewPassword_SUCCESS": {
      return {
        ...state,
        SetSuccess: true,
        ShowSetNEwLoader: false,
      };
    }
    case "setNewPassword_FAILURE": {
      return {
        ...state,
        SetError: true,
        ShowSetNEwLoader: false,
      };
    }
    case "setNewPasswordSpin_START": {
      return {
        ...state,
        ShowSetNEwLoader: true,
      };
    }
    case "Login_SUCCESS": {
      localStorage.setItem("userInfo", JSON.stringify(action.payload.user));
      localStorage.setItem("token", action.payload.user.token);
      return {
        ...state,
     
        loginSuccess: true,
        token: action.payload.user.token,
        authUser: action.payload.user,
        loginStart: false,
      };
    }
    case "Login_FAILURE": {
      return {
        ...state,
        loginStart: false,
        loginFail: true,
        loginFailMessage:action.payload
      };
    }
    
    case "INIT_URL": {
      return { ...state, initURL: action.payload };
    }
    case "updateSetting_SUCCESS": {
      return {
        ...state,
        deliveryCostList: action.payload.Record,
        updateSettingSuccess: true,
        SettingMessage: "Update Setting Successfully",
        updateSpinner: false,
      };
    }
    case "getSetting_SUCCESS": {
      return {
        ...state,
        deliveryCostList: action.payload.Record,
      };
    }
    case "getSetting_FAILURE": {
      return {
        ...state,
        deliveryCostList: {},
      };
    }
    case "updateSettingSpin_START": {
      return {
        ...state,
        updateSpinner: true,
      };
    }
    case "updateSetting_FAILURE": {
      return {
        ...state,
        updateSettingError: true,
        updateSpinner: false,
        SettingMessage: "Some error occur try later",
      };
    }
    case "UN_AUTHORIZED": {
      return {
        ...state,
        unAuthorized_modal: true,
      };
    }
    case "UN_AUTHORIZED_close": {
      return {
        ...state,
        unAuthorized_modal: false,
      };
    }
    case "logout_success": {
      localStorage.clear();
      return {
        ...state,
        logoutSuccess: true,
        initURL: "/",
        token: null,
        authUser: null,
      };
    } 
    case "saveEvent": {
      localStorage.setItem("userInfo", JSON.stringify(action.userInfo));
      return {
        ...state,
        token: action.token,
        authUser: action.userInfo,
        TokenSaveSuccess: true,
      };
    }

    case "profileSpinner_START": {
      return {
        ...state,
        profileLoader: true,
      };
    }
    case "gettingProfile_START": {
      return {
        ...state,
        fetchingLoader: true,
      };
    }
    case "getProfile_SUCCESS": {
      return {
        ...state,
        settingsData: action.payload.Record,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
      };
    }
    case "changePassword_Modal": {
      return {
        ...state,
        changePasswordModelVisible: !state.changePasswordModelVisible,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
      }; 
    }
    case "EditProfile_Modal": {
      return {
        ...state,
        editProfileModalVisible: !state.editProfileModalVisible,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
      };
    }
    case "resetSpinner_START": {
      return {
        ...state,
        fetchLoader: true,
      };
    }
    case "resetPassword_SUCCESS": {
      return {
        ...state,
        changePasswordModelVisible: !state.changePasswordModelVisible,
        isPasswordChanged: true,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
        fetchLoader:false,
        resetFail:false,
        textMessage: "your password has been changed successfully",
      };
    }
    case "resetPassword_FAILURE": {
      return {
        ...state,
        fetchLoader:false,
        isPasswordChanged: false,
        resetSuccess: false,
        resetFail: true,
        ResetLoading: false,
        textMessage: "Request Fail Plz try agin later",
      };
    }
    case "updateProfile_SUCCESS": {
      const updatedValue = updateProfile(state.authUser, action.payload.Record);
      return {
        ...state,
        editProfileModalVisible: !state.editProfileModalVisible,
        settingsData: action.payload.Record,
        authUser: { ...updatedValue },
        profileEditeSuccess: true,
        textMessage: "Admin profile has been updated successfully",
        updatingProfile: false,
      };
    }
    case "reset_profilesettings": {
      return {
        ...state,
        isPlanSettingsFail: false,
        isPlanSettingsUpdated: false,
        fetchingLoader: false,
        fetchLoader: false,
        isPasswordChanged: false,
        profileEditeSuccess: false,
        updatingProfile: false,
        profileLoader: false,
      };
    }
    case "update_auth": {
      return {
        ...state,
        updateSettingSuccess: false,
        SettingMessage: "",
        updateSettingError: false,
        success: false,
        isPasswordChanged:false,
        resetSuccess: false,
        resetFail: false,
        error: false,
        message: "",
        otpSuccess: false,
        otpLoader: false,
        ShowSetNewLoader: false,
        ShowForgotLoader: false,
        userRegisterSuccessfully: false,
        userRegisterFail: false,
        savingDetail: false,
        savingLoginDetail: false,
        loginDetailChangeSuccessfully: false,
        loginDetailChangeFail: false,
        loginFail: false,
        loginSuccess: false,
        logoutSuccess: false,
        loginStart: false,
        ShowRestLoader: false,
        TokenSaveSuccess: false,
        ForSuccess: false,
        ForError: false,
        SetSuccess: false,
        SetError: false,
        ShowSetNEwLoader: false,
      };
    }

    default:
      return state;
  }
};
