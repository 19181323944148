import React,{useEffect,useState} from 'react'
import FormModal from 'components/Modal/FormModal'
import StudentForm from './studentForm'
import { intlPlaceholder } from 'util/intlPlaceholder'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
const AddStudent = () => {
  const [form] = Form.useForm()
  const { ADD_Student_MODAL } = useSelector((state) => state.CurdR)

  const [photoList, setPhotoState] = useState([]);
  useEffect(()=>{
    if(!ADD_Student_MODAL){
      setPhotoState([])
    }
  },[ADD_Student_MODAL])
  
   
  return (
    <FormModal
      addButtonName={intlPlaceholder('Student.addStudent')}
      modalTitle={intlPlaceholder('Student.addStudentTitle')}
      submitButtonText={intlPlaceholder('Student.addbutton')}
      addApiName="addStudent"
      ModalName="ADD_Student_MODAL"
      visible={ADD_Student_MODAL}
      width={650}
      recordName={'studentList'}
      form={form}
      extraFieldName="profileImageUrl"
      extraFieldValue={photoList}
    >
      <StudentForm setPhotoState={setPhotoState} photoList={photoList}/>
    </FormModal>
  )
}

export default AddStudent