import React from "react";
import { renderStatus } from "utils/commonUseFunction";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Avatar, Rate } from "antd";
import { UserOutlined } from "@ant-design/icons";
import StudentList from "../../Table/List";
import phoneFormatter from "phone-formatter";
const DashboardList = ({instructorList}) => {
  const columns = [
    {
      title: intlPlaceholder("#"),
      render: (record) => (
        <span>
         <Avatar
            className="gx-mr-2"
            size={45}
            src={(record.profileImageUrl && record.profileImageUrl.length && record.profileImageUrl[0].imageUrl) || ""}
            icon={!record.profileImageUrl[0] && <UserOutlined />}
          />
        </span>
      ),
      key: "image",
    },
    {
      title: intlPlaceholder("DashboardList.FullName"),
      render: (record) => <span style={{whiteSpace:"nowrap"}}>{record.fullName}</span>,
      key: "fullName",
      width:"200px"
    },
    {
      title: intlPlaceholder("student.DashboardListEmail"),
      render: (record) => (
        <span >{record.email}</span>
      ),
      key: "email",
    },
    {
      title: intlPlaceholder("student.DashboardListPhoneNumber"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {phoneFormatter.format(record.phoneNumber, "(NNN) NNN-NNNN", {
            normalize: false,
          }) || "----"}
        </span>
      ),
      key: "phoneNumber",
    },

  
    {
      title: intlPlaceholder("instructor.Status"),
      render: (record) => (
        <span >
          {renderStatus(record.status)} 
        </span>
      ),
      key: "status",
    },

    {
      title: "Rating",
      render: (record) => (
        <span >
          <Rate
            style={{
              color: "#0054B6",
            }}
            value={record.courseRating}
          />
        </span>
      ),
      key: "action",
      width: "250px",
    },
    {
      title: intlPlaceholder("students"),
      render: (record) => <span className="gx-text-capatilize">{record.totalStudent}</span>,
      key: "students",
    },
  ];



  return (
    <StudentList
      columns={columns}
      scroll={true}
      pagination={true}
      tempData={instructorList}
    />
  );
};

export default DashboardList;
