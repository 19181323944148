import React, { useEffect, useState } from "react";
import { Form, Select, Input, Spin, message } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import { DataGetAction } from "redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
const SalesForm = ({ form, setID }) => {
  const dispatch = useDispatch();

  const [notEnrolled, setNotEnrolled] = useState();

  const studentProps = useSelector((state) => state.CurdR);

  const {
    courseTitle,
    salesFormRecord,
    studentSuccess,
    courseSalesPrice,
    ADD_Sales_MODAL,
  } = studentProps;

  const handleChange = (value) => {
    if (value) {
      dispatch(
        DataGetAction(
          "getCoursePrice",
          "FetchRecord",
          { _id: value },
          "",
          "",
          "courseSalesPrice"
        )
      );
    }
  };

  const onSearch = (value) => {
    if (value) {
      dispatch(
        DataGetAction(
          "getStudentByStudentId",
          "FetchRecord",
          { studentId: value },
          "StudentSpinner",
          "",
          "salesFormRecord"
        )
      );
    }
  };

  useEffect(() => {
    if (!ADD_Sales_MODAL) {
      setNotEnrolled("");
    }
  }, [ADD_Sales_MODAL]);

  useEffect(() => {
    if (salesFormRecord && salesFormRecord.length > 0) {
      let name = salesFormRecord[0].fullName;
      let enrolledCoueses = salesFormRecord[0].enrolledCourses;
      let id = salesFormRecord[0]._id;
      setID(id);
      form.setFieldValue("fullName", name);

      const results = courseTitle.filter(
        ({ _id: id1 }) =>
          !enrolledCoueses.some(({ courseId: id2 }) => id2 === id1)
      );
      console.log("====item", results);

      setNotEnrolled(results);
    } else if (salesFormRecord && salesFormRecord.length === 0) {
      form.setFieldValue("fullName", "");
      return message.error(
        "Current Student Id is Invalid or Student is not active"
      );
    } else {
      form.resetFields();
    }
  }, [salesFormRecord, form, setID]);

  useEffect(() => {
    if (courseSalesPrice && courseSalesPrice.length > 0) {
      let value = courseSalesPrice[0].coursePrice;
      form.setFieldValue("coursePrice", value);
    }
  }, [courseSalesPrice, form]);

  return (
    <div className="gx-px-5">
      <Form.Item
        label={intlPlaceholder("sales.id")}
        name="studentId"
        rules={[
          {
            required: true,
            message: intlPlaceholder("sales.message"),
          },
        ]}
      >
        <Input.Search
          type={"number"}
          min={0}
          onSearch={onSearch}
          placeholder={intlPlaceholder("salesid.placeholder")}
        />
      </Form.Item>
      {studentSuccess && studentSuccess ? (
        <Spin className="gx-d-flex gx-align-items-center gx-justify-content-center"></Spin>
      ) : (
        !studentSuccess && (
          <Form.Item
            label={intlPlaceholder("sales.studentName")}
            name="fullName"
            rules={[
              {
                required: true,
                message: intlPlaceholder("sales.name"),
              },
            ]}
          >
            <Input
              disabled
              placeholder={intlPlaceholder("salesName.placeholder")}
            />
          </Form.Item>
        )
      )}
      <Form.Item
        name="courseName"
        label={intlPlaceholder("sales.courseSelect")}
        rules={[
          {
            required: true,
            message: intlPlaceholder("sales.selectCourse"),
          },
        ]}
      >
        <Select
          showSearch
          onChange={handleChange}
          placeholder={intlPlaceholder("Instructor.courseplaceholder")}
        >
          {notEnrolled &&
            notEnrolled.map((item) => {
              return (
                <Select.Option value={item._id}>
                  {item.courseTitle}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item
        label={intlPlaceholder("sales.Price")}
        name="coursePrice"
        rules={[
          {
            required: true,
            message: intlPlaceholder("sales.Courseprice"),
          },
        ]}
      >
        <Input
          disabled
          type={"number"}
          placeholder={intlPlaceholder("salesPrice.placeholder")}
        />
      </Form.Item>

      {/* <Form.Item label={intlPlaceholder("sales.date")} 
        name="createdAt"
        rules={[
          {
            required: true,
            message: intlPlaceholder("sales.Date"),
          },
        ]}
        >
          <DatePicker className="gx-w-100"/>
        </Form.Item> */}
    </div>
  );
};
export default SalesForm;
