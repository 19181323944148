import React from "react";
import {MdDashboard} from "react-icons/md";
import {FaChalkboardTeacher} from "react-icons/fa";
import {FaUserGraduate} from "react-icons/fa";
import {BiLibrary} from "react-icons/bi";
import {FaMeetup} from "react-icons/fa";
import {FaThList} from "react-icons/fa";
import {MdAssignment} from "react-icons/md";
import {FaMoneyBill} from "react-icons/fa";
import {FaHandsHelping} from "react-icons/fa";
import {RiFileListFill} from "react-icons/ri";
import {AiFillNotification} from "react-icons/ai";
import {AiTwotoneSetting} from "react-icons/ai";
import { AiFillBell } from "react-icons/ai";
const List =  {
  admin: [
    {
      title: "sidebarItem.dashboard",
      url: "/dashboard",
      icon: <MdDashboard size={18} />,
      key: "dashboard",
      top: "3px",
    },

    {
      title: "sidebarItem.Instructor",
      url: "/instructor",
      icon: <FaChalkboardTeacher size={18}/>,
      key: "instructor",
      top: "3px",
    },
    {
      title: "sidebarItem.Student",
      url: "/student",
      icon: <FaUserGraduate size={18}/>,
      key: "student",
      top: "3px",
    },
    {
      title: "sidebarItem.Course",
      url: "/course",
      icon: <BiLibrary size={18}/>,
      key: "course",
      top: "3px",
    },
    {
      title: "sidebarItem.meeting",
      url: "",
      icon: <FaMeetup size={18}/>,
      key: "meeting",
      top: "3px",
      subMenu: [
        {
          title: "sidebarItem.request",
          url: "/meeting/request",
          key: "request",
          top: "3px",
        },
        {
          title: "sidebarItem.settings",
          url: "/meeting/settings",
          key: "settings",
          top: "3px",
        },
      ],
    },
    {
      title: "sidebarItem.quiz",
      url: "/quiz",
      icon: <FaThList size={18}/>,
      key: "quiz",
      top: "3px",
    },
    {
      title: "sidebarItem.assignments",
      url: "/assignment",
      icon: <MdAssignment size={18}/>,
      key: "assignment",
      top: '3px',
    },
    {
      title: "sidebarItem.Financial",
      url: "",
      icon: <FaMoneyBill size={18}/>,
      key: "financial",
      top: "3px",
      subMenu: [
        {
          title: "sidebarItem.salesReport",
          url: "/financial/salesReport",
          
          key: "financial/salesReport",
          top: "3px",
        },
        {
          title: "sidebarItem.financialSummary",
          url: "/financial/financialSummary",
         
          key: "financial/financialSummary",
          top: "3px",
        },
      ],
    },

    {
      title: "sidebarItem.support",
      url: "/support",
      icon: <FaHandsHelping size={18}/>,
      key: "support",
    },
    {
      title: "sidebarItem.Banner",
      url: "/banner",
      icon: <AiFillBell size={18}/>,
      key: "student",
      top: "3px",
    },
    {
      title: "sidebarItem.noticeboard",
      url: "/noticeboard",
      icon: <RiFileListFill size={18}/>,
      key: "noticeboard",
      top: "3px",
    },

    {
      title: "sidebarItem.notification",
      url: "/notification",
      icon: <AiFillNotification size={18}/>,
      key: "notification",
      top: "3px",
    },

    {
      title: "sidebarItem.setting",
      url: "/setting",
      icon: <AiTwotoneSetting size={18}/>,
      key: "setting",
      top: "3px",
    },
  ],
};

export default List;
