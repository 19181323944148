import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { Input, Row, Col } from "antd";
import { useSelector } from "react-redux";
import ViewAssignments from "./viewAssignments";
const TitleDetails = () => {
  const curdProps = useSelector((state) => state.CurdR);
  const { TitleDetail_Modal, initialValues } = curdProps;

  const renderData = (label, value) => {
    return (
      <Col
        xl={24}
        lg={24}
        md={24}
        sm={24}
        xs={24}
        className=" gx-d-flex  gx-justify-content-between"
      >
        <div className="gx-w-50 gx-d-flex gx-align-items-center">
          <h5> {label}:</h5>
        </div>
        <div className="gx-w-100">
          <Input disabled value={value} className="gx-pt-2"/>
        </div>
      </Col>
    );
  };
  return (
    <>
      <SimpleModal
        width={530}
        modalFor="TitleDetail_Modal"
        visible={TitleDetail_Modal}
      >
        <h2 className="gx-text-capitalize gx-font-sans-bold">
          {initialValues && initialValues.assignmentTitle}
        </h2>
        <hr></hr>
        {/* <p className="gx-mt-0">
          Created by 
          <span className="gx-font-sans-bold  gx-px-1 gx-text-capitalize">
            {initialValues && initialValues.fullName}
          </span>
          {initialValues &&
            moment(initialValues.createdAt).format("DD-MMM-YYYY")}
        </p> */}

        <div className="gx-px-3">
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Assignment Title",
              `${initialValues && initialValues.assignmentTitle}`
            )}
          </Row>
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Course",
              `${initialValues && initialValues.courseTitle}`
            )}
          </Row>
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Type",
              `${initialValues && initialValues.assignmentType}`
            )}
          </Row>
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Questions",
              `${initialValues && initialValues.noOfQuestions}`
            )}
          </Row>
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Total Marks",
              `${initialValues && initialValues.totalMarks}`
            )}
          </Row>
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Passing Marks",
              `${initialValues && initialValues.passingMarks}`
            )}
          </Row>
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Due Date",
              `${initialValues && initialValues.dueDate}`
            )}
          </Row>
        </div>
      </SimpleModal>
      <ViewAssignments />
    </>
  );
};

export default TitleDetails;
