import React from "react";
import { Typography } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import SearchBox from "../../../../components/Filters/SearchBox/index";
import SelectFilter from "../../../../components/Filters/SelectFilter";
const { Title } = Typography;
const TableHeader = ({ setFilterValue }) => {
  return (
    <div
      className="gx-d-flex gx-justify-content-between gx-align-items-center gx-mb-3 "
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold gx-mb-0">
        {intlPlaceholder("request.pageTittle")}
      </Title>
      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
        <SearchBox
          apiName="getMeetingRequest"
          requestType="FetchTableRecord"
          placeholder={intlPlaceholder("meetingRequest.titleDay")}
          recordName="requestList"
          width={"170px"}
          customQuery={{ query: "search", key: "name" }}
          useSetFilterValue={true}
          setFilterValue={setFilterValue}
        />
       
        <SelectFilter
          apiName="getMeetingRequest"
          requestType="FetchTableRecord"
          recordName="requestList"
          requestType="FetchTableRecord"
          placeholder={intlPlaceholder("request.select")}
          searchKey="role"
          width={"180px"}
          isShowingAll={true}
          setFilterValue={setFilterValue}
          useSetFilterValue={true}
          customQuery={{ pageNumber: 1, limit: 5 }}
          options={[
            { status: "instructor", label: "Instructor" },
            { status: "student", label: "Student" },
          ]}
          ItemValue="status"
          label="label"
        />
        <SelectFilter
          apiName="getMeetingRequest"
          recordName="requestList"
          requestType="FetchTableRecord" 
          placeholder={intlPlaceholder("request.Role")}
          searchKey="status"
          width={"180px"}
          isShowingAll={true}
          setFilterValue={setFilterValue}
          useSetFilterValue={true}
          customQuery={{ pageNumber: 1, limit: 5 }}
          options={[
            { status: "pending", label: "Pending" },
            { status: "cancelled", label: "Cancelled" },
            { status: "completed", label: "Completed" },
            { status: "schedule", label: "Scheduled" },
          ]}
          ItemValue="status"
          label="label"
        />
      </div>
    </div>
  );
};

export default TableHeader;
