import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import {Input, Row, Col} from "antd";
import { useSelector } from "react-redux";
const { TextArea } = Input;

const TitleDetails = () => {
  const curdProps = useSelector((state) => state.CurdR);
  const { TitleDetail_Modal, initialValues } = curdProps;

  const renderData = (label, value) => {
    return (
      <Col xl={12} lg={12} md={12} sm={12} xs={24} className="d-column">
        <h5> {label}</h5>
        <Input disabled value={value} />
      </Col>
    );
  };
  return (
    <>
      <SimpleModal
        width={530}
        modalFor="TitleDetail_Modal"
        visible={TitleDetail_Modal}
      >
        <h2 className="gx-text-capitalize gx-font-sans-bold">
          {initialValues && initialValues.quizTitle}
        </h2>
<hr></hr>
        {/* <p className="gx-mt-0">
          Created by 
          <span className="gx-font-sans-bold  gx-px-1 gx-text-capitalize">
            {initialValues && initialValues.fullName}
          </span>
          {initialValues &&
            moment(initialValues.createdAt).format("DD-MMM-YYYY")}
        </p> */}
       
        <div className="gx-px-3">
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Quiz Title",
              `${initialValues && initialValues.quizTitle}`
            )}
            {renderData(
              "Course",
              `${initialValues && initialValues.courseTitle}`
            )}
          </Row>
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Time(minute)",
              `${initialValues && initialValues.quizTime}`
            )}
            {renderData("Date", `${initialValues && initialValues.quizDate}`)}
          </Row>
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Total Marks",
              `${initialValues && initialValues.totalMarks}`
            )}
            {renderData(
              "Passing Marks",
              `${initialValues && initialValues.passMarks}`
            )}
          </Row>
          <Row className="gx-flex-row gx-mt-2">
            {renderData(
              "Number of Attempts",
              `${initialValues && initialValues.numberOfAttempts}`
            )}
            {renderData(
              "Total Questions",
              `${initialValues && initialValues.noOfQuestions}`
            )}
          </Row>
        
          <Row className="gx-flex-row gx-mt-2">
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="d-column">
              <h5>Description</h5>
              <TextArea rows={4} disabled value={initialValues && initialValues.description} />
            </Col>
          </Row>
        </div>
      </SimpleModal>
    </>
  );
};

export default TitleDetails;
