import React, { useState } from "react";
import SupportList from "../../components/Table/PageNationTable";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import { intlPlaceholder } from "util/intlPlaceholder";
import moment from "moment";
import { renderStatus } from "../../utils/commonUseFunction";
import { renderSubstring } from "../../utils/commonUseFunction";
import { useHistory } from "react-router-dom";
import ChartSupport from "./components/ChartSupport";
const Support = () => {
  const history = useHistory();
  const [filterValue, setFilterValue] = useState({});
  const columns = [
    {
      title: intlPlaceholder("record.id"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.supportId}</span>
      ),
      key: "id",
      width:"100px"
    },
    {
      title: intlPlaceholder("record.subject"),
      render: (record) => (
        <span
          onClick={() => history.push("/viewSupportSubject")}
          className="gx-text-capitalize gx-text-link gx-text-blue gx-pointer"
        >
          {renderSubstring(record.subject, "50","50")}
        </span>
      ),
      key: "subject",
    },
    {
      title: intlPlaceholder("record.role"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.role}</span>
      ),
      key: "role",
    },
    {
      title: intlPlaceholder("record.date"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.date).format("MM-DD-YYYY")}
        </span>
      ),
      key: "date",
    },
    {
      title: intlPlaceholder("record.status"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status)}
        </span>
      ),
      key: "status",
    },
  ];

  // const data = [
  //   {
  //     id: "1001",
  //     subject: "Lorem Ipsum is simply dummy text...",
  //     role: "Teacher",
  //     date: "12-Jul-2022 08:40",
  //     status: "active",
  //   },
  // ];

  return (
    <Layout>
      <ChartSupport/>
      <Widget>
        <TableHeader />
        <SupportList
          apiName="getSupport"
          recordName="supportList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
    </Layout>
  );
};

export default Support;
