import React, { useState } from "react";
import NoticeboardList from "components/Table/PageNationTable";
import ActionDropdown from "components/Table/Actions";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import moment from "moment";
import { message } from "antd";
import EditNoticeboard from "./components/EditNoticeBoard";
import ShowModal from "components/Modal/ShowModal";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import TitleDetails from "./components/titleDetails";
import { useDispatch, useSelector } from "react-redux";
import ChartNoticeBoard from "./components/chartComponent";
import { intlPlaceholder } from "util/intlPlaceholder";

const Noticeboard = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState({});

  const CurdProps = useSelector((state) => state.CurdR);
  const { success, error } = CurdProps;

  if (success) {
    message.success(CurdProps.message);
    dispatch({
      type: "ResetAll_State",
    });
  }

  if (error) {
    message.error(CurdProps.message);
    dispatch({
      type: "ResetAll_State",
    });
  }

  const renderActions = (record) => {
    return (
      <div
        style={{ gap: "5px" }}
        className="gx-d-flex gx-flex-column popoverContent"
      >
        <ShowModal
          modalName={"EDIT_Notice_MODAL"}
          buttonName="Edit"
          record={record}
        />
        <DeleteConfirm
          type="deleteNoticeBoardRecord"
          recordName="noticeBoardList"
          selectedItem={record}
          apiRequest="DeleteTableRecord"
          buttonName={intlPlaceholder("noticeBoard.buttonName")}
          oKText={intlPlaceholder("noticeBoard.oKText")}
          title={intlPlaceholder("noticeBoard.deleteTitle")}
          content={intlPlaceholder("noticeBoard.deleteContent")}
          Icon={intlPlaceholder("noticeBoard.actionDeleteName")}
        />
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("ID"),
      render: (record) => <span>{record.noticeBoardId}</span>,
      key: "id",
      width: "100px",
    },
    {
      title: intlPlaceholder("record.title"),
      render: (record) => (
        <span className="gx-text-capitalize gx-link gx-text-blue  gx-overflow-ellipsis">
          {
            <ShowModal modalName={"TitleDetail_Modal"} record={record}>
              {record.noticeTitle
                ? record.noticeTitle.length > 40
                  ? record.noticeTitle.slice(0, 30) + "...."
                  : record.noticeTitle
                : ""}
            </ShowModal>
          }
        </span>
      ),
      key: "NoticeboardTitle",
    },
    {
      title: intlPlaceholder("record.type"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.role === "studentAndInstructor"
            ? "Student And Instructor"
            : record.role}
        </span>
      ),
      key: "NoticeboardType",
    },
    {
      title: intlPlaceholder("record.message"),
      render: (record) => (
        <span
          dangerouslySetInnerHTML={{
            __html: record.message
              ? record.message.length > 40
                ? record.message.slice(0, 30) + "...."
                : record.message
              : "",
          }}
          className="gx-text-capitalize gx-pointer"
        ></span>
      ),
      key: "NoticeboardMessage",
    },
    {
      title: intlPlaceholder("record.date"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.createdAt).format("MM-DD-YYYY")}
        </span>
      ),
      key: "Date",
      width: "150px",
    },

    {
      title: "",
      render: (record) => (
        <ActionDropdown>{renderActions(record)}</ActionDropdown>
      ),
      key: "action",
      width: "50px",
    },
  ];

  return (
    <Layout>
      <ChartNoticeBoard />

      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <NoticeboardList
          apiName="getNoticeBoard"
          recordName="noticeBoardList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
      <TitleDetails />
      <EditNoticeboard />
    </Layout>
  );
};

export default Noticeboard;
